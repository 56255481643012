import React from 'react';
import Header from './Header';
import Footer from './Footer';
import con15 from './images/con15.jpeg'
const Overview = () => {
    return (
        <div>
            <Header />
            <section className="about-section three-image about_page animated-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-head">
                                <h4><span>N D Connect Overview</span></h4>
                                <h3 style={{ textAlign: "left" }}>N D Connect Overview</h3>
                                <p style={{ textAlign: "left" }}>
                                    N D Connect is a comprehensive business management platform designed by N D Techland Private Limited to streamline and automate your daily operations. Whether you run a small business or a large enterprise, N D Connect offers an all-in-one solution that integrates various business functions into a single, user-friendly interface.
                                </p>
                                <p style={{ textAlign: "left" }}>
                                    N D Connect is designed with flexibility in mind, enabling customization to meet the unique needs of different industries. From retail and hospitality to manufacturing and services, N D Connect helps businesses optimize their processes, improve efficiency, and drive growth.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img
                                src={con15}
                                alt="ND Connect Overview"
                                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Overview;
