import React, { useState } from 'react';
import './Customersupport.css';
import Header from './Header';
import Footer from './Footer';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
const Customersupport = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch('https://api.ndtechland.com/api/HomeApi/Contactus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Name: formData.name,
                Email: formData.email,
                Message: formData.message,
            }),
        });

        if (response.ok) {

            alert('Your message has been sent successfully!');
            setFormData({ name: '', email: '', message: '' });
        } else {

            alert('There was an error sending your message. Please try again.');

        }
    };


    return (
        <>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <section className="customer-support-section">
                            <div className="container">
                                <h1>Customer Support</h1>

                                <div className="support-details">
                                    <div className="support-form">
                                        <h2>Submit a Request</h2>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="name">Name:</label>
                                                <input
                                                    type="text"
                                                    id="name"

                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email:</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required

                                                    style={{ width: "100%", textAlign: "left" }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="message">Message:</label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                    style={{ width: "100%", textAlign: "left" }}
                                                />
                                            </div>
                                            <button type="submit" className="submit-btn">
                                                Submit
                                            </button>
                                        </form>
                                    </div>

                                    <div className="support-info">
                                        <h2>Contact Us</h2>
                                        <p>For immediate assistance, please contact us:</p>
                                        <div className="contact-item">
                                            <FaMapMarkerAlt className="contact-icon" />
                                            <div className="contact-info">
                                                <p><strong>Address:</strong></p>
                                                <p>C - 53, First Floor, Sector 2, Noida, Uttar Pradesh - 201301</p>
                                            </div>
                                        </div>
                                        <div className="contact-item">
                                            <FaPhoneAlt className="contact-icon" />
                                            <div className="contact-info">
                                                <p><strong>Phone:</strong> 0120 4354103</p>
                                            </div>
                                        </div>
                                        <div className="contact-item">
                                            <FaEnvelope className="contact-icon" />
                                            <div className="contact-info">
                                                <p><strong>Email:</strong> <a href="mailto:info@ndtechland.com">help@ndtechland.com</a></p>
                                            </div>
                                        </div>
                                        <p>Hours: Monday to Satursday, 9:30 AM - 6:30 PM</p>
                                    </div>
                                </div>


                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Customersupport;
