import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Datasecurity = () => {
    return (
        <div>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3>Data Security</h3>
                        <ul>
                            <li>
                                <h5>1. Our Commitment to Data Security</h5>
                                <p>At N D Techland Private Limited, we take the security of your data seriously. We are committed to protecting your information from unauthorized access, disclosure, alteration, and destruction.</p>
                            </li>
                            <li>
                                <h5>2. Security Measures</h5>
                                <ul>
                                    <li>
                                        <h6><b>Encryption</b></h6>
                                        <p>We use encryption protocols to protect your data during transmission and storage.</p>
                                    </li><br />
                                    <li>
                                        <h6><b>Access Controls</b></h6>
                                        <p>We implement strict access controls to ensure that only authorized personnel can access your data.</p>
                                    </li><br />
                                    <li>
                                        <h6><b>Regular Audits</b></h6>
                                        <p>We conduct regular security audits and assessments to identify and address potential vulnerabilities.</p>
                                    </li><br />
                                    <li>
                                        <h6><b>Data Backup</b></h6>
                                        <p>We perform regular data backups to ensure that your information is not lost in case of a system failure or other unforeseen events.</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <h5>3. Your Role in Data Security</h5>
                                <p>While we do our best to protect your data, it’s important that you also take steps to ensure your security. This includes using strong passwords, keeping your login credentials confidential, and being cautious when sharing personal information online.</p>
                            </li>
                            <li>
                                <h5>4. Data Breach Response</h5>
                                <p>In the unlikely event of a data breach, we have a response plan in place to address the issue promptly. We will notify affected users as required by law and take appropriate steps to mitigate the impact.</p>
                            </li>
                            <li>
                                <h5>5. Compliance with Regulations</h5>
                                <p>We comply with all applicable data protection laws and regulations, including the General Data Protection Regulation (GDPR) for users in the European Union. We ensure that your rights are respected and your data is handled in accordance with legal requirements.</p>
                            </li>
                            <li>
                                <h5>6. Contact Us</h5>
                                <p>If you have any questions or concerns about data security, please contact our Data Protection Officer at <a href="mailto:support@ndtechland.com">support@ndtechland.com</a>. We are here to help and ensure that your data remains secure.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Datasecurity
