import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Cookiepolicy = () => {
    return (
        <div>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3>Effective Date:</h3>
                        <ul>
                            <li>
                                <h5>1. Introduction</h5>
                                <p>This Cookie Policy explains how N D Techland Private Limited uses cookies and similar technologies on our website. By using our website, you consent to the use of cookies in accordance with this policy.</p>
                            </li>
                            <li>
                                <h5>2. What Are Cookies?</h5>
                                <p>Cookies are small text files that are placed on your device when you visit a website. They help us recognize your device, remember your preferences, and improve your overall experience on our site.</p>
                            </li>
                            <li>
                                <h5>3. Types of Cookies We Use</h5>
                                <ul>
                                    <li>
                                        <h6>Essential Cookies:</h6>
                                        <p>These cookies are necessary for the website to function properly. They enable basic features like page navigation and access to secure areas of the site.</p>
                                    </li>
                                    <li>
                                        <h6>Analytical Cookies:</h6>
                                        <p>These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.</p>
                                    </li>
                                    <li>
                                        <h6>Functional Cookies:</h6>
                                        <p>These cookies allow the website to remember your preferences, such as your language or region, and provide enhanced features.</p>
                                    </li>
                                    <li>
                                        <h6>Marketing Cookies:</h6>
                                        <p>These cookies track your online activity to help us deliver more relevant advertisements.</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <h5>4. Managing Cookies</h5>
                                <p>You can manage your cookie preferences through your browser settings. Most browsers allow you to block or delete cookies, but doing so may affect your ability to use certain features of our website.</p>
                            </li>
                            <li>
                                <h5>5. Third-Party Cookies</h5>
                                <p>We may also allow third-party service providers to place cookies on your device to help us analyze website traffic or deliver targeted advertisements. These third parties have their own privacy policies, and we encourage you to review them.</p>
                            </li>
                            <li>
                                <h5>6. Changes to This Policy</h5>
                                <p>We may update this Cookie Policy from time to time to reflect changes in our use of cookies. Any updates will be posted on our website, and we recommend reviewing this policy regularly.</p>
                            </li>
                            <li>
                                <h5>7. Contact Us</h5>
                                <p>If you have any questions about our use of cookies, please contact us at <a href="mailto:support@ndtechland.com">support@ndtechland.com</a>.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Cookiepolicy
