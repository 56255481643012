import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './Helpcenter.css';
import Header from './Header';
import Footer from './Footer';

const HelpCenterContact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestData = {
            Name: formData.name,
            Email: formData.email,
            Message: formData.message
        };

        try {
            const response = await fetch('https://api.ndtechland.com/api/HomeApi/AddHelpCenter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            const data = await response.json();

            if (data.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Close'
                });

                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: data.message || 'Something went wrong. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error: ' + error.message,
                icon: 'error',
                confirmButtonText: 'Close'
            });
        }
    };

    return (
        <section className="helpcenter-contact">
            <h2>Contact Support</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        style={{ textAlign: "left" }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        style={{ textAlign: "left" }}
                    ></textarea>
                </div>
                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </section>
    );
};

const Helpcenter = () => {
    return (
        <>
            <Header />

            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <section className="helpcenter-page">
                            <div className="container helpcenter-content">
                                <div className="helpcenter-header">
                                    <h1>Help Center</h1>
                                    <p>We're here to help you with any questions or concerns.</p>
                                </div>

                                <div className="helpcenter-sections">
                                    <section className="helpcenter-faq">
                                        <h2>Frequently Asked Questions</h2>
                                        <div className="faq-item">
                                            <h3>How do I reset my password?</h3>
                                            <p>You can reset your password by clicking the "Forgot Password" link on the login page.</p>
                                        </div>
                                        <div className="faq-item">
                                            <h3>How do I contact support?</h3>
                                            <p>You can contact support by emailing support@example.com or using the contact form below.</p>
                                        </div>
                                        <div className="faq-item">
                                            <h3>Where can I find the user manual?</h3>
                                            <p>The user manual is available in the Resources section of your account dashboard.</p>
                                        </div>
                                    </section>

                                    <HelpCenterContact />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Helpcenter;
