import React from 'react';
import './Aboutsection.css';
import { Link } from 'react-router-dom';
import About1 from '../src/images/About1.png'; // Ensure the image path is correct

const Aboutsection = () => {
    return (
        <div>
            <section className="about_section section-b-space">
                <div className="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                                <div className="inner-column">
                                    <div className="sec-title">
                                        <span className="title">About Us</span>
                                        <h2 style={{textAlign : "left"}}>About N D Techland Private Limited</h2>
                                    </div>
                                    <div className="text">
                                        At N D Techland Private Limited, we specialize in developing innovative software solutions that empower businesses to reach their full potential. Our commitment to excellence and customer satisfaction drives us to create products that solve real-world challenges and help our clients thrive in a competitive market.
                                    </div>
                                    <div className="text">
                                        N D Connect is more than just software; it's a strategic partner in your business's success. Whether you're a small business or a large enterprise, our platform adapts to your needs and scales as you grow.
                                    </div>

                                    <h4>Get Started Today</h4>
                                    <div className="text">
                                        Ready to take control of your business operations? Experience the power of N D Connect. Contact us today to learn more or to schedule a demo. Let us help you manage your business more efficiently and effectively.
                                    </div>
                                    <div className="btn-box">
                                        <Link to="/Contactus">
                                            <button className="theme-btn btn-style-one">Contact Us</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="image-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column wow fadeInLeft">
                                    <div className="author-desc">
                                        <h2>N D Connect</h2>
                                        <span>Connects The Tech Dots</span>
                                    </div>
                                    <figure className="image-1">
                                        <a href="#" className="lightbox-image" data-fancybox="images">
                                            <img src={About1} alt="About N D Techland" />
                                        </a>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Aboutsection;
