import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import './BlogDetails.css';
import { useParams } from 'react-router-dom';

const Blogdetails = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await fetch(`https://api.ndtechland.com/api/HomeApi/GetBlogById?Id=${id}`);
        const data = await response.json();

        if (data.status === 200) {
          setBlogPost({
            title: data.data.title,
            content: data.data.content,
            author: data.data.addedBy,
            date: new Date(data.data.createdAt).toLocaleDateString(),
            image: `https://api.ndtechland.com/image/${data.data.blogImage}`,
          });
        } else {
          setError('Failed to fetch blog post');
        }
      } catch (err) {
        setError('An error occurred while fetching the blog post');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!blogPost) return null;

  return (
    <div className="blog-details">
      <Header />
      <section className="blog-section">
        <div className="container">
          <div className="blog-content">
            <h1 className="blog-title">{blogPost.title}</h1>
            <p className="blog-meta"><strong>By {blogPost.author}</strong> | {blogPost.date}</p>
            <div className="blog-body">
              <img src={blogPost.image} alt={blogPost.title} className="blog-image" />
              <div className="blog-text" dangerouslySetInnerHTML={{ __html: blogPost.content }} />
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Blogdetails;
