import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import './Events.css';
import 'react-calendar/dist/Calendar.css';

const Events = () => {
  const [hoveredDate, setHoveredDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch('https://api.ndtechland.com/api/EmployeeApi/GetHolidayandEvents', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        if (data.succeeded) {
          const allEvents = [
            ...data.data.officeEventsDtos.map(event => ({
              title: event.tittle,
              subtittle: event.subtittle,
              date: new Date(event.date),
              type: 'Holiday',
            })),
            ...data.data.meetEventsDtos.map(event => ({
              title: event.eventTittle,
              subtittle: event.eventType,
              date: new Date(event.eventdate),
              type: 'Meeting',
              time: event.eventTime,
              description: event.eventDescription,
            }))
          ];
          setEvents(allEvents);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const onDateHover = (date, e) => {
    const event = events.find((event) => event.date.toLocaleDateString() === date.toLocaleDateString());
    if (event) {
      const tileElement = e.target.closest('.react-calendar__tile');
      const rect = tileElement.getBoundingClientRect();

      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      let topPosition = rect.top + window.scrollY + 20;
      if (spaceBelow < 100 && spaceAbove > 100) {
        topPosition = rect.top + window.scrollY - 120;
      }

      setPopupPosition({
        top: topPosition,
        left: rect.left + window.scrollX + 10,
      });

      setHoveredDate(event);
    } else {
      setHoveredDate(null);
    }
  };

  return (
    <div className="events-calendar">
      <Calendar
        onActiveStartDateChange={() => setHoveredDate(null)}
        tileContent={({ date, view, onClick }) => {
          const event = events.find((event) => event.date.toLocaleDateString() === date.toLocaleDateString());
          return (
            <div
              onMouseEnter={(e) => onDateHover(date, e)}
              onMouseLeave={() => setHoveredDate(null)}
              className={`event-marker ${event ? 'has-event' : ''}`}
              style={{ cursor: 'pointer' }}
            >
              {event && <span className="dot"></span>}
            </div>
          );
        }}
      />

      {hoveredDate && (
        <div
          className="event-details-popup"
          style={{
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
            visibility: hoveredDate ? 'visible' : 'hidden',
          }}
        >
          <div className="popup-content">
            <div className="popup-header">
              <h3 className="popup-title">{hoveredDate.title}</h3>
              <p className="popup-date">{hoveredDate.date.toDateString()}</p>
            </div>
            <div className="popup-body">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/46557/Time.svg"
                alt="Time"
                className="popup-icon"
              />
              <p className="popup-entertainment">{hoveredDate.subtittle}</p>
              {hoveredDate.type === 'Meeting' && (
                <div>
                  <p className="popup-time">Time: {hoveredDate.time}</p>
                  <a href={hoveredDate.description} target="_blank" rel="noopener noreferrer" className="popup-link">
                    Join Meeting
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;
