import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import './Pricingplan.css';

const Pricingplan = () => {
    const [pricingPlans, setPricingPlans] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPricingPlans = async () => {
            try {
                const response = await axios.get('https://api.ndtechland.com/api/HomeApi/GetPricingPlan');
                if (response.data.status === 200) {
                    setPricingPlans(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching pricing plans:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPricingPlans();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Header />
            <section className="pricing-section">
                <div className='container'>
                    <div className="section-head text-center">
                        <h4><span>Pricing Plans</span></h4>
                        <h3>N D Connect Pricing Plans</h3>
                        <p>
                            N D Connect offers a range of flexible pricing plans designed to fit businesses of all sizes and budgets. Whether you’re just starting out or managing a large enterprise, we have a plan that meets your needs.
                        </p>
                    </div>

                    <div className="row text-center">
                        {pricingPlans.map(plan => (
                            <div key={plan.id} className="col-lg-3 col-md-6 mb-4">
                                <div className="card h-100">
                                    <img
                                        src={`https://api.ndtechland.com/image/${plan.image}`}
                                        alt={plan.planName}
                                        className="card-img-top fixed-height"
                                    />
                                    <div className="card-body">
                                        <h4 className="card-title text-center">{plan.planName}</h4>
                                        <h6 className='text-center'>{plan.title}</h6>


                                        <div className="pricing-details">
                                            {plan.planName === "Enterprise Plan" ? (
                                                <>
                                                    <p><strong>Monthly Subscription:</strong> Custom Pricing</p>
                                                    <p><strong>Annual Subscription:</strong> Custom Pricing</p>

                                                </>
                                            ) : (
                                                <>
                                                    <p><strong>Monthly Subscription:</strong> ₹{plan.price.toFixed(2)}</p>
                                                    <p><strong>Annual Subscription:</strong> ₹{plan.annulPrice.toFixed(2)}</p>
                                                    <p><strong>Save:</strong> ₹{plan.savePrice.toFixed(2)} (Save {plan.annulPriceInPercentage}% annually)</p>
                                                </>
                                            )}
                                        </div>


                                        <div className="card-text feature">
                                            <strong>Features:</strong>
                                            <ul className="features-list">
                                                {plan.planFeatures.map(feature => (
                                                    <li key={feature.id}>{feature.feature}</li>
                                                ))}
                                            </ul>
                                        </div>

                                        <p><strong>Support:</strong> {plan.support}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="section-foot text-center">
                        <p>
                            All our plans come with 24/7 customer support and regular software updates. We also offer a free trial period for new customers, allowing you to experience the benefits of N D Connect before committing to a subscription.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Pricingplan;
