import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Publicrelation = () => {
    return (
        <div>

            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3>Public Relations (PR) & Marketing Services</h3>
                        <p>
                            Building a strong brand reputation and maintaining positive relationships with your stakeholders are essential for long-term success. Our PR and Marketing services are designed to help you communicate effectively with your audience, manage your brand’s image, and enhance your market presence.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Media Relations</b></h5>
                                <p>
                                    We help you build and maintain relationships with key media outlets, ensuring that your brand receives positive coverage. Our media relations services include writing and distributing press releases, organizing press conferences, and securing media interviews.
                                </p>
                            </li>
                            <li>
                                <h5><b>Brand Management</b></h5>
                                <p>
                                    Protecting and enhancing your brand’s reputation is critical in today’s competitive market. Our brand management services include brand strategy development, reputation management, crisis communication, and brand monitoring.
                                </p>
                            </li>
                            <li>
                                <h5><b>Event Management</b></h5>
                                <p>
                                    Events are a powerful way to connect with your audience and promote your brand. We offer end-to-end event management services, including planning, organizing, and executing corporate events, product launches, conferences, and trade shows.
                                </p>
                            </li>
                            <li>
                                <h5><b>Influencer Marketing</b></h5>
                                <p>
                                    Leverage the power of influencers to reach new audiences and build credibility. We identify the right influencers for your brand, develop collaborative campaigns, and measure the impact of influencer partnerships on your business.
                                </p>
                            </li>
                            <li>
                                <h5><b>Crisis Management</b></h5>
                                <p>
                                    In the event of a crisis, effective communication is key to minimizing damage and maintaining trust. Our crisis management services include crisis planning, communication strategy development, and media handling to help you navigate challenging situations.
                                </p>
                            </li>
                            <li>
                                <h5><b>Strategic Marketing Consulting</b></h5>
                                <p>
                                    Our marketing experts work with you to develop and implement a comprehensive marketing strategy that aligns with your business goals. We analyze market trends, identify opportunities, and create a roadmap for your marketing efforts to maximize ROI.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default Publicrelation
