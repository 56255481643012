import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Usersguide.css';

const Usersguide = () => {
  const [tutorials, setTutorials] = useState([]);

  useEffect(() => {
    const fetchTutorials = async () => {
      try {
        const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetOurTutorials');
        const data = await response.json();

        if (data.status === 200) {
          setTutorials(data.data);
        } else {
          console.error('Error fetching tutorials:', data.message);
        }
      } catch (error) {
        console.error('Error fetching tutorials:', error);
      }
    };

    fetchTutorials();
  }, []);

  return (
    <div>
      <Header />

      <section className="tutorial-section">
        <div className="container">
          <div className="section-head text-center">
            <h4><span>User Guide</span></h4>
            <p className="section-description">
              Watch our video tutorials for quick and easy learning. Whether you're a beginner or an advanced user, our tutorials will guide you through the various features of N D Connect.
            </p>
          </div>

          <div className="row ">
            <div className="col-md-6">
              <div className="row">
                {tutorials.map(tutorial => (
                  <div className="col-md-5 col-lg-8" key={tutorial.id}>
                    <div className="video-card border rounded shadow-sm d-flex flex-column">
                      <div className="video-thumbnail position-relative">
                        <iframe
                          className="rounded"
                          width="100%"
                          height="250"
                          src={`https://api.ndtechland.com/TutorialVideos/${tutorial.vedioUrl}`}
                          title={tutorial.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                        <div className="play-overlay position-absolute top-50 start-50 translate-middle">
                          <i className="bi bi-play-circle-fill"></i>
                        </div>
                      </div>
                      <div className="video-info p-3">
                        <h5 className="video-title">{tutorial.title}</h5>
                        <p className="video-description">{tutorial.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-6">
              <div className="manual-section">
                <div className="manual-card">
                  <div className="card text-center">
                    <div className="card-body">
                      <a
                        href="https://example.com/manual1.pdf"
                        download
                        className="manual-link d-flex flex-column align-items-center"
                      >
                        <i className="fas fa-file-pdf" style={{ fontSize: '24px', marginBottom: '8px' }}></i>
                        <span>Download Manual</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Usersguide;
