import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Featurebenifits = () => {
    const [features, setFeatures] = useState([]);
    const apiUrl = "https://api.ndtechland.com/api/HomeApi/GetFeaturesandBenefits";

    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                const response = await fetch(apiUrl);
                const data = await response.json();
                if (data.status === 200) {
                    setFeatures(data.data);
                } else {
                    console.error('Error fetching features:', data.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchFeatures();
    }, []);

    return (
        <div>
            <Header />
            <section className="about-section three-image about_page animated-section">
                <div className="container d-flex flex-column align-items-center justify-content-center">
                    <div className="section-head col-sm-12 text-center">
                        <h4><span>Features & Benefits</span></h4>
                    </div>

                    {features.map((feature, index) => (
                        <div className="row feature-row align-items-center mb-5" key={feature.id}>
                            <div className={`col-md-6 ${index % 2 === 0 ? '' : 'order-md-2'} text-center text-md-left`}>
                                <h3>{feature.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: feature.content }} />
                            </div>
                            <div className={`col-md-6 ${index % 2 === 0 ? '' : 'order-md-1'} text-center`}>
                                <img src={`https://api.ndtechland.com/image/${feature.image}`} alt={feature.title} className="img-fluid rounded" />
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Featurebenifits;
