import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Privacypolicy = () => {
    // Inline styles
    const sectionStyle = {
        listStyleType: 'none', // Removes default bullet points
        padding: 0,
    };

    const listItemStyle = {
        marginBottom: '10px', // Adds space between list items
    };

    return (
        <div>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3>Privacy Policy</h3>
                        <p>
                            At N D Techland Private Limited ("we," "our," "us"), we take the privacy and security of your personal information seriously. This Privacy Policy explains how we collect, use, share, and protect your information when you use our services, including the N D Connect platform.
                        </p>
                        <p>
                            By accessing or using our website or services, you agree to this Privacy Policy. If you do not agree, please discontinue use of our services.
                        </p>
                        <p>
                            Please note: This Privacy Policy may be updated at any time to reflect changes in our practices or in compliance with government regulations and guidelines. Any changes will be posted on our website, and we encourage you to review this policy periodically.
                        </p>

                        <h5>1. Information We Collect</h5>
                        <p>We collect various types of information to provide you with personalized and efficient services.</p>
                        <h6>1.1 <strong>Personal Information</strong></h6>
                        <p>Personal information is data that identifies you as an individual. This may include:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Name:</strong> Your full name.</li><br />
                            <li style={listItemStyle}><strong>Email address:</strong> Your email address for communication.</li><br />
                            <li style={listItemStyle}><strong>Phone number:</strong> Your contact number.</li><br />
                            <li style={listItemStyle}><strong>Company name:</strong> The name of your company.</li><br />
                            <li style={listItemStyle}><strong>Billing information:</strong> Information required for billing.</li><br />
                            <li style={listItemStyle}><strong>Login credentials:</strong> Username and password for account access.</li><br />
                        </ul>
                        <h6>1.2 <strong>Non-Personal Information</strong></h6>
                        <p>Non-personal information refers to data that does not directly identify you, such as:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>IP address:</strong> Your internet protocol address.</li><br />
                            <li style={listItemStyle}><strong>Browser type and version:</strong> Information about the browser you use.</li><br />
                            <li style={listItemStyle}><strong>Device and operating system details:</strong> The device and OS you are using.</li><br />
                            <li style={listItemStyle}><strong>Location data:</strong> Location for attendance validation.</li><br />
                            <li style={listItemStyle}><strong>Service usage patterns:</strong> Patterns including time and duration of access.</li><br />
                        </ul>
                        <h6>1.3 <strong>Cookies and Tracking Technologies</strong></h6>
                        <p>We use cookies and similar tracking technologies to enhance your experience, gather analytics, and ensure seamless login. You may adjust your browser settings to refuse cookies; however, doing so may limit certain features of our service.</p>

                        <h5>2. How We Use Your Information</h5>
                        <p>We use the information we collect for the following purposes:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Service Provision:</strong> To enable access to the N D Connect platform and its features, such as customer management, invoicing, employee tracking, and payment reminders.</li><br />
                            <li style={listItemStyle}><strong>Communication:</strong> To send service updates, marketing materials, technical notices, and other administrative messages.</li><br />
                            <li style={listItemStyle}><strong>Service Improvement:</strong> To analyze usage patterns and feedback to enhance and optimize our services.</li><br />
                            <li style={listItemStyle}><strong>Legal Compliance:</strong> To comply with applicable laws and regulations and enforce our agreements.</li><br />
                        </ul>

                        <h5>3. How We Share Your Information</h5>
                        <p>Your personal information is only shared under specific circumstances:</p>
                        <h6>3.1 <strong>With Third-Party Service Providers</strong></h6>
                        <p>We work with third-party vendors who assist us in delivering our services. These providers are only given access to your information to perform specific services on our behalf and are obligated to maintain confidentiality.</p>
                        <h6>3.2 <strong>For Legal Purposes</strong></h6>
                        <p>We may disclose your information if required by law or if we believe such action is necessary to protect our rights or comply with legal obligations.</p>
                        <h6>3.3 <strong>Business Transactions</strong></h6>
                        <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction.</p>

                        <h5>4. Data Security</h5>
                        <p>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, or destruction. Our security protocols include:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Encryption of sensitive data:</strong> Protecting sensitive data through encryption methods.</li><br />
                            <li style={listItemStyle}><strong>Secure data storage practices:</strong> Storing data securely to prevent unauthorized access.</li><br />
                            <li style={listItemStyle}><strong>Limiting access to authorized personnel only:</strong> Ensuring that only authorized individuals can access personal information.</li><br />
                        </ul>
                        <p>Despite our efforts, no method of data transmission or storage can be guaranteed to be 100% secure. We cannot promise absolute security, but we are committed to protecting your data to the best of our abilities.</p>

                        <h5>5. Data Retention</h5>
                        <p>We retain personal data for as long as necessary to fulfill the purposes for which it was collected or as required by law. Once your information is no longer needed, we will securely delete or anonymize it in compliance with data protection regulations.</p>

                        <h5>6. Employee Location for Attendance Validation</h5>
                        <p>The N D Connect platform may collect employee location data from mobile devices for attendance validation. This ensures that attendance is accurately recorded based on the verified work location as designated by the employer. The data is used solely for attendance verification purposes and will not be shared with third parties unless required by law.</p>

                        <h5>7. Your Rights</h5>
                        <p>You have the following rights regarding your personal data:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Access:</strong> You can request access to the personal information we hold about you.</li><br />
                            <li style={listItemStyle}><strong>Correction:</strong> You can ask us to update or correct any inaccurate data.</li><br />
                            <li style={listItemStyle}><strong>Deletion:</strong> You can request that we delete your personal data, subject to certain legal obligations.</li><br />
                            <li style={listItemStyle}><strong>Opt-Out:</strong> You can opt out of marketing communications at any time.</li><br />
                            <li style={listItemStyle}><strong>Data Portability:</strong> You may request a copy of your data in a machine-readable format.</li><br />
                        </ul>
                        <p>To exercise these rights, please contact us at <a href="mailto:info@ndtechland.com">info@ndtechland.com</a>.</p>

                        <h5>8. Children’s Privacy</h5>
                        <p>Our services are not directed toward children under the age of 18. We do not knowingly collect personal information from children. If we become aware of data collection from children, we will take steps to delete such information promptly.</p>

                        <h5>9. Changes to This Privacy Policy</h5>
                        <p>We reserve the right to modify this Privacy Policy at any time in accordance with changes in company practices, services, or applicable laws and government guidelines. Any changes will be posted on our website. It is your responsibility to review this policy periodically to stay informed about our data practices.</p>

                        <h5>10. Contact Us</h5>
                        <p>If you have any questions or concerns regarding this Privacy Policy or how we handle your data, please contact us at:</p>
                        <p>
                            N D Techland Private Limited<br />
                            Email: <a href="mailto:info@ndtechland.com">info@ndtechland.com</a><br />
                            Phone : 0120-4354103 <br />
                            Website: www.ndtechland.com
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Privacypolicy;
