import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Datasafety = () => {
    const sectionStyle = {
        listStyleType: 'none',
        padding: 0,
    };

    const listItemStyle = {
        marginBottom: '10px',
    };

    return (
        <div>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3><strong>Data Safety and Web Security</strong></h3>
                        <p>
                            At N D Techland Private Limited, we prioritize the safety and security of your data while using our product N D Connect. We implement stringent security measures to ensure that your personal, business, and operational data are protected at all times. Below is a detailed outline of our data safety and web security practices:
                        </p>

                        <h5><strong>1. Data Safety</strong></h5>
                        <p>We take the utmost care in safeguarding your data to prevent unauthorized access, breaches, and misuse. Our approach includes:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Data Encryption:</strong> All sensitive information, including login credentials and transaction details, is encrypted using SSL (Secure Socket Layer) technology during transmission to ensure that it remains secure.</li><br />
                            <li style={listItemStyle}><strong>Storage Security:</strong> Data stored on our servers is protected with advanced encryption methods. We use state-of-the-art security solutions to keep your information safe from unauthorized access.</li><br />
                            <li style={listItemStyle}><strong>Regular Backups:</strong> We conduct regular backups of all critical data to ensure that, in the event of system failures, data can be quickly restored without any loss or disruption.</li><br />
                            <li style={listItemStyle}><strong>Role-Based Access Control (RBAC):</strong> Access to your account and data is restricted based on roles and permissions, ensuring that only authorized personnel can access sensitive information.</li><br />
                        </ul>

                        <h5><strong>2. Web Security</strong></h5>
                        <p>Our web security protocols are designed to protect your data from cyber threats and attacks while using N D Connect. These include:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Secure Servers:</strong> Our web services are hosted on secure servers, protected with advanced firewalls, to prevent unauthorized intrusions and cyberattacks.</li><br />
                            <li style={listItemStyle}><strong>HTTPS Protocol:</strong> N D Connect uses the HTTPS protocol for all data transmissions, ensuring a secure and encrypted connection between your browser and our server.</li><br />
                            <li style={listItemStyle}><strong>Two-Factor Authentication (2FA):</strong> We provide an extra layer of security with Two-Factor Authentication, requiring a secondary verification step during login to ensure secure access to your account.</li><br />
                            <li style={listItemStyle}><strong>Account Protection:</strong> Our system automatically flags suspicious login attempts, and we notify users of any unusual activities. This helps in preventing unauthorized access to your account.</li><br />
                            <li style={listItemStyle}><strong>Regular Security Audits:</strong> We conduct regular internal and external audits to identify vulnerabilities and ensure that our security protocols are up to date with the latest industry standards.</li><br />
                        </ul>

                        <h5><strong>3. Compliance with Data Protection Regulations</strong></h5>
                        <p>We comply with applicable data protection regulations to ensure that your personal data is processed in accordance with privacy laws and standards. These regulations guide how we collect, store, and use your information. We respect your privacy and ensure that your data is only used for legitimate purposes as outlined in our Privacy Policy.</p>

                        <h5><strong>4. User Responsibilities</strong></h5>
                        <p>While we employ robust security measures, the safety of your account also depends on how you manage your login credentials. We encourage users to:</p>
                        <ul style={sectionStyle}>
                            <li style={listItemStyle}><strong>Use strong passwords and update them regularly.</strong></li><br />
                            <li style={listItemStyle}><strong>Keep login credentials confidential and not share them with unauthorized individuals.</strong></li><br />
                            <li style={listItemStyle}><strong>Notify us immediately of any suspicious activity or unauthorized access to your account.</strong></li><br />
                        </ul>

                        <h5><strong>5. Continuous Monitoring and Improvement</strong></h5>
                        <p>We continuously monitor our systems for potential vulnerabilities and threats. We also work to improve our security infrastructure by adopting the latest technologies and practices to ensure that your data remains safe.</p>

                        <p>
                            For any concerns or questions regarding data safety and web security, please feel free to contact us at <a href="mailto:info@ndtechland.com">info@ndtechland.com</a> or call 0120-4354103. Your security is our top priority.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Datasafety;
