import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Usermanuals.css';
import jsPDF from 'jspdf';

const manualsData = [
  { title: 'User Manual 1', description: 'Description of User Manual 1' },
  { title: 'User Manual 2', description: 'Description of User Manual 2' },
  { title: 'User Manual 3', description: 'Description of User Manual 3' },
];

const Usermanuals = () => {
  const generatePDF = () => {
    const doc = new jsPDF();

    manualsData.forEach((manual, index) => {
      doc.text(manual.title, 10, 10 + index * 20);
      doc.text(manual.description, 10, 15 + index * 20);
    });

    doc.save('user_manuals.pdf');
  };

  return (
    <>
      <Header />

      <div className="manuals-container">
        {manualsData.map((manual, index) => (
          <div className="manual-card" key={index}>
            <h3>{manual.title}</h3>
            <p>{manual.description}</p>
          </div>
        ))}
      </div>

      <button onClick={generatePDF}>Download Manuals as PDF</button>

      <Footer />
    </>
  );
};

export default Usermanuals;
