import React, { useState } from 'react';
import './Faq.css'; // Custom styling
import Header from './Header';
import Footer from './Footer';

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFaq = (index) => {
        setOpenIndex(openIndex === index ? null : index); 
    };

    const faqData = [
        {
            question: 'What is N D Connect?',
            answer: 'N D Connect is an all-in-one business management platform designed to streamline your operations. It covers everything from customer management and invoicing to employee attendance tracking and payment reminders, all within one intuitive software.',
        },
        {
            question: 'Who can benefit from using N D Connect?',
            answer: 'N D Connect is ideal for businesses of all sizes across various industries. Whether you\'re a small startup or a large enterprise, our platform adapts to your specific needs, helping you manage your business more effectively.',
        },
        {
            question: 'How does the location-based attendance system work?',
            answer: 'Our attendance system integrates with Google Maps to verify employees\' locations when they log in. Companies can set a specific location radius, and if an employee tries to clock in outside of this area, they won\'t be able to do so. This ensures accurate attendance records and helps prevent fraudulent logins.',
        },
        {
            question: 'Can I customize N D Connect to fit my business needs?',
            answer: 'Yes, N D Connect is highly customizable. We understand that every business is unique, so our platform allows you to tailor its features to match your specific requirements.',
        },
        {
            question: 'How secure is my data on N D Connect?',
            answer: 'Security is our top priority. N D Connect uses advanced encryption and security protocols to protect your data. We also offer regular backups and secure access controls to ensure that your information is safe.',
        },
        {
            question: 'What kind of support do you offer?',
            answer: 'We provide comprehensive support to all our customers. Whether you need help with setup, customization, or troubleshooting, our dedicated support team is here to assist you every step of the way.',
        },
        {
            question: 'Can I try N D Connect before committing?',
            answer: 'Absolutely! We offer a free demo of N D Connect so you can explore its features and see how it can benefit your business. Contact us to schedule your demo today.',
        },
    ];


    return (
        <>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <div className="faq-container">
                            <h1>Frequently Asked Questions</h1>
                            <div className="faq-list">
                                {faqData.map((faq, index) => (
                                    <div
                                        key={index}
                                        className={`faq-item ${openIndex === index ? 'open' : ''}`} // Apply 'open' class conditionally
                                    >
                                        <div className="faq-question" onClick={() => toggleFaq(index)}>
                                            <h3>{faq.question}</h3>
                                            <span>{openIndex === index ? '-' : '+'}</span>
                                        </div>
                                        {openIndex === index && (
                                            <div className="faq-answer">
                                                {faq.answer}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Faq;
