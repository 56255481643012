import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Banner from './Banner'
import Aboutsection from './Aboutsection'
import Thirdsection from './Thirdsection'
import Gallerysection from './Gallerysection'


const Home = () => {

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div>
      <Header />
      <Banner />
      {/* <Searchsection /> */}
      <Thirdsection />
      <Aboutsection />
      <Gallerysection />
      {/* <Specialsection /> */}
      {/* <Videosection /> */}
      {/* <Awesomeservice /> */}
      {/* <Subscribe /> */}
      {/* <Happycustomer /> */}
      <Footer />

      <div className="tap-top" onClick={handleScrollTop} style={{ cursor: 'pointer' }}>
        <div>
          <i className="fas fa-angle-up"></i>
        </div>
      </div>

    </div>
  )
}

export default Home
