import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Digitalmarketing = () => {
    return (
        <>

            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3> Digital Marketing Services</h3>
                        <p>
                            In the digital age, an effective online presence is key to attracting and retaining customers. Our Digital Marketing services are designed to help you reach your target audience, build brand awareness, and drive sales through a strategic approach to online marketing.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Search Engine Optimization (SEO)</b></h5>
                                <p>
                                    Improve your website’s visibility on search engines with our SEO services. We conduct keyword research, optimize on-page elements, and build high-quality backlinks to boost your search engine rankings and drive organic traffic to your site.
                                </p>
                            </li>
                            <li>
                                <h5><b>Pay-Per-Click (PPC) Advertising</b></h5>
                                <p>
                                    Reach potential customers quickly with targeted PPC campaigns. Our experts create and manage ads on platforms like Google AdWords and social media, optimizing them for maximum ROI. We handle everything from keyword selection to ad copy and bid management.
                                </p>
                            </li>
                            <li>
                                <h5><b>Social Media Marketing</b></h5>
                                <p>
                                    Engage with your audience and build your brand on social media platforms like Facebook, Instagram, LinkedIn, and Twitter. Our social media marketing services include content creation, community management, paid promotions, and analytics to measure success.
                                </p>
                            </li>
                            <li>
                                <h5><b>Content Marketing</b></h5>
                                <p>
                                    Content is at the heart of digital marketing. We create compelling content that resonates with your audience and supports your marketing goals. From blog posts and articles to videos and infographics, our content marketing services help you tell your brand’s story effectively.
                                </p>
                            </li>
                            <li>
                                <h5><b>Email Marketing</b></h5>
                                <p>
                                    Nurture leads and retain customers with personalized email campaigns. Our email marketing services include designing responsive email templates, writing engaging content, segmenting your audience, and analyzing campaign performance.
                                </p>
                            </li>
                            <li>
                                <h5><b>Website Development & Design</b></h5>
                                <p>
                                    Your website is often the first point of contact for potential customers. We offer website development and design services to create user-friendly, responsive, and visually appealing websites that reflect your brand and drive conversions.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Digitalmarketing
