import React, { useEffect, useState } from 'react';
import './Userguide.css';
import Header from './Header';
import Footer from './Footer';

const pdfIcon = "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg";

const Userguide = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/path/to/user-guide.pdf';
        link.download = 'User_Guide.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [tutorials, setTutorials] = useState([]);

    useEffect(() => {
        const fetchTutorials = async () => {
            try {
                const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetOurTutorials');
                const data = await response.json();

                if (data.status === 200) {
                    setTutorials(data.data);
                } else {
                    console.error('Error fetching tutorials:', data.message);
                }
            } catch (error) {
                console.error('Error fetching tutorials:', error);
            }
        };

        fetchTutorials();
    }, []);

    return (
        <>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <section className="user-guide-section">
                            <div className="container">
                                <h1>User Guide</h1>

                                <div className="tutorial-section">
                                    <h3>Video Tutorials</h3>
                                    <div className="tutorial-list">
                                        {tutorials.map(tutorial => (
                                            <div className="tutorial-item" key={tutorial.id}>
                                                <h3>{tutorial.title}</h3>
                                                <iframe
                                                    src={`https://api.ndtechland.com/TutorialVideos/${tutorial.vedioUrl}`}
                                                    title={tutorial.title}
                                                    allowFullScreen
                                                ></iframe>
                                                {tutorial.description}
                                            </div>
                                        ))}
                                    </div>
                                </div>


                                <div className="tutorial-section pdf-card" onClick={handleDownload}>
                                    <h3>Download User Guide (PDF)</h3>
                                    <img src={pdfIcon} alt="PDF Icon" className="pdf-icon" />
                                    <p>Click to download the full user guide in PDF format.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Userguide;
