import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const Requestdemo = () => {
    const [showModal, setShowModal] = useState(false);
    const [demoData, setDemoData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        companyName: '',
        description: ''
    });

    useEffect(() => {
        const fetchDemoData = async () => {
            try {
                const response = await axios.get('https://api.ndtechland.com/api/HomeApi/GetRequestDemo');
                if (response.data.status === 200) {
                    setDemoData(response.data.data);
                } else {
                    console.error('Error fetching demo data:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching demo data:', error);
            }
        };

        fetchDemoData();
    }, []);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.ndtechland.com/api/HomeApi/AddRequestDemo', {
                Name: formData.name,
                Email: formData.email,
                Mobile: formData.mobile,
                CompanyName: formData.companyName,
                Description: formData.description
            });
            if (response.data.status === 200) {
                alert(response.data.message);
                handleClose();
                setFormData({ name: '', email: '', mobile: '', companyName: '', description: '' });
            } else {
                console.error('Error submitting demo request:', response.data.message);
            }
        } catch (error) {
            console.error('Error submitting demo request:', error);
        }
    };

    return (
        <div>
            <Header />

            <section className="request-demo-section">
                <div className="container">

                    <div className="section-head text-center mb-4">
                        <h4><span>Request a Demo</span></h4>
                    </div>

                    <div className="text-center mb-5">
                        <h3 className="section-subtitle">Experience the Power of N D Connect</h3>
                        <p className="section-description">
                            Experience the power of N D Connect firsthand with a personalized demo. Our team will walk you through the platform, showing you how it can be tailored to meet your business’s unique needs.
                        </p>
                    </div>

                    <div className="row">
                        {demoData.map((demo) => (
                            <div className="col-lg-6 mb-4" key={demo.id}>
                                <div className="card demo-card shadow-sm">
                                    <img src={`https://api.ndtechland.com/image//${demo.image}`} className="card-img-top" alt={demo.title} />
                                    <div className="card-body">
                                        <h5 className="card-title">{demo.title}</h5>
                                        <div className="card-text" dangerouslySetInnerHTML={{ __html: demo.content }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="text-center mt-5">
                        <p className="section-description mb-4">
                            Our demos are tailored to your business’s needs, ensuring you get the most out of N D Connect.
                        </p>
                        <Button variant="primary" onClick={handleShow}>
                            Request a Demo Now
                        </Button>
                    </div>
                </div>
            </section>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Request a Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">Phone</label>
                            <input
                                type="text"
                                className="form-control"
                                id="mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="companyName" className="form-label">Company</label>
                            <input
                                type="text"
                                className="form-control"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Message</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                rows="3"
                                value={formData.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Footer />
        </div>
    );
};

export default Requestdemo;
