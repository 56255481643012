import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './Casestudy.css'
const Casestudy = () => {
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetCaseStudies');
        const data = await response.json();

        if (data.status === 200) {
          setCaseStudies(data.data);
        } else {
          console.error('Failed to fetch case studies:', data.message);
        }
      } catch (error) {
        console.error('Error fetching case studies:', error);
      }
    };

    fetchCaseStudies();
  }, []);

  return (
    <>
      <Header />

      <section className="casestudy-section">
        <div className="container">
          <div className="section-head text-center">
            <h4 style={{ marginTop: "6rem" }}><span>Our Case Studies</span></h4>
            
            <p className="section-description">
              Discover how N D Connect has transformed businesses across industries. Explore our case studies to see the real-world impact of our platform.
            </p>
          </div>

          <div className="row g-4">
            {caseStudies.map((study) => (
              <div key={study.id} className="col-12 col-md-6 col-lg-4">
                <div className="case-study-item position-relative">
                  <img
                    src={`https://api.ndtechland.com/image/${study.image}`}
                    className="img-fluid w-100"
                    alt={study.title}
                  />
                  <div className="case-study-overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className="text-center p-3">
                      <h5 className="text-white">{study.title}</h5>
                      <div className="text-white">
                        <span className="small" dangerouslySetInnerHTML={{ __html: study.description }} />
                      </div>
                      <Link to={`/Casestudydetails/${study.id}`} className="btn btn-outline-light btn-sm mt-2">
                        Read More
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Casestudy;
