import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; 
import './Userlogin.css';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
const UserLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();  // Initialize useNavigate

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            console.log('Sending data:', { employee_ID: email, password: password });

            const response = await axios.post('https://api.ndtechland.com/api/Account/Login', {
                employee_ID: email,
                password: password,
            });

            console.log('Response:', response.data);

            if (response.data.response.succeeded) {
                localStorage.setItem('token', response.data.token);

                Swal.fire({
                    title: 'Success!',
                    text: response.data.response.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    navigate('/Dashboards');
                });

            } else {
                setErrorMessage(response.data.response.message);
            }
        } catch (error) {
            console.error('Error:', error.response || error);
            setErrorMessage('Login failed. Please check your credentials and try again.');
        }
    };


    return (
        <>
            <Header />
            <div className="login-container">
                <div className="login-box">
                    <section className="animated-section">
                        <div className="">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-sm-8 col-12">
                                    <div className="account-sign-in shadow-lg p-4 rounded">
                                        <div className="title text-center">
                                            <h2>Login</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email" style={{ textAlign: "left" }}>Employee Id</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password" style={{ textAlign: "left" }}>Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    placeholder="Enter your password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="rememberMe"
                                                />
                                                <label className="form-check-label" htmlFor="rememberMe" style={{ textAlign: "left" }}>Remember me</label>
                                            </div>
                                            <div className="button-bottom mt-4">
                                                <button type="submit" className="w-100 btn btn-primary btn-lg">Login</button>
                                                <div className="divider text-center my-3">
                                                    <h6>Web Portal</h6>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UserLogin;
