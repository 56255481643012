import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BankDetail.css'; 

const Bankdetail = () => {
  const [accHolderName, setAccHolderName] = useState('');
  const [bankName, setBankName] = useState('');
  const [accNo, setAccNo] = useState('');
  const [reEnterAccNo, setReEnterAccNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [accType, setAccType] = useState('current'); 
  const [epfNumber, setEpfNumber] = useState('');
  const [nominee, setNominee] = useState('');
  const [chequeImage, setChequeImage] = useState(null);
  const [chequeBase64, setChequeBase64] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token'); 

    axios
      .get('https://api.ndtechland.com/api/EmployeeApi/GetBankdetail', {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      })
      .then((response) => {
        const data = response.data.data;
        setAccHolderName(data.accountHolderName || '');
        setBankName(data.bankName || '');
        setAccNo(data.accountNumber || '');
        setReEnterAccNo(data.reEnterAccountNumber || '');
        setIfscCode(data.ifsc || '');
        setAccType(data.accountTypeId === 2 ? 'saving' : 'current'); 
        setEpfNumber(data.epfNumber || '');
        setNominee(data.nominee || '');
        setChequeImage(data.chequebase64 ? `data:image/png;base64,${data.chequebase64}` : null);
      })
      .catch((error) => {
        console.error('Error fetching bank details:', error);
      });
  }, []);

  const handleChequeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setChequeImage(reader.result);
        setChequeBase64(reader.result.split(',')[1]); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = () => {
    const token = localStorage.getItem('token'); 
    const formData = new FormData();
    formData.append('AccountHolderName', accHolderName);
    formData.append('BankName', bankName);
    formData.append('AccountNumber', accNo);
    formData.append('ReEnterAccountNumber', reEnterAccNo);
    formData.append('Ifsc', ifscCode);
    formData.append('AccountTypeId', accType === 'saving' ? 2 : 1); 
    formData.append('EpfNumber', epfNumber);
    formData.append('Nominee', nominee);
    formData.append('Chequebase64', chequeBase64);
    axios
      .post('https://api.ndtechland.com/api/EmployeeApi/EmployeeBankDetail', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Bank details updated successfully:', response.data);
        alert('Bank details updated successfully');
      })
      .catch((error) => {
        console.error('Error updating bank details:', error);
        alert('Error updating bank details');
      });
  };

  return (
    <div className="bank-detail-container">
      <h2>Bank Details</h2>
      <form className="bank-detail-form">
        <div className="form-group">
          <label>ACC Holder Name:</label>
          <input 
            type="text" 
            value={accHolderName} 
            onChange={(e) => setAccHolderName(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>Bank Name:</label>
          <input 
            type="text" 
            value={bankName} 
            onChange={(e) => setBankName(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>Acc No:</label>
          <input 
            type="text" 
            value={accNo} 
            onChange={(e) => setAccNo(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>Re-Enter Acc No:</label>
          <input 
            type="text" 
            value={reEnterAccNo} 
            onChange={(e) => setReEnterAccNo(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>IFSC Code:</label>
          <input 
            type="text" 
            value={ifscCode} 
            onChange={(e) => setIfscCode(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label>Acc Type:</label>
          <div className="radio-group">
            <label>
              <input 
                type="radio" 
                value="current" 
                checked={accType === 'current'} 
                onChange={(e) => setAccType(e.target.value)} 
              />
              Current
            </label>
            <label>
              <input 
                type="radio" 
                value="saving" 
                checked={accType === 'saving'} 
                onChange={(e) => setAccType(e.target.value)} 
              />
              Saving
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>EPF Number:</label>
          <input 
            type="text" 
            value={epfNumber} 
            onChange={(e) => setEpfNumber(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Nominee:</label>
          <input 
            type="text" 
            value={nominee} 
            onChange={(e) => setNominee(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Upload Cheque:</label>
          <input 
            type="file" 
            accept="image/*" 
            onChange={handleChequeUpload} 
          />
        </div>
        <div className="update-container">
          <button type="button" className="update-button" onClick={handleUpdate}>Update</button>
          {chequeImage && (
            <div className="cheque-preview">
              <h4>Uploaded Cheque:</h4>
              <img src={chequeImage} alt="Uploaded Cheque" />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Bankdetail;
