import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import './Contactus.css';

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://api.ndtechland.com/api/HomeApi/Contactus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Name: formData.name,
        Email: formData.email,
        Message: formData.message,
      }),
    });

    if (response.ok) {
      // Handle successful submission (e.g., show a success message)
      alert('Your message has been sent successfully!');
      setFormData({ name: '', email: '', message: '' }); // Clear the form
    } else {
      // Handle errors (e.g., show an error message)
      alert('There was an error sending your message. Please try again.');

    }
  };

  return (
    <div>
      <Header />

      <section className="contact-hero">
        <div className="container">
          <div className="section-head col-sm-12">
            <h4><span>Get in Touch</span></h4>
          </div>
          <div className="hero-content text-center">
            <p className="section-description">
              We’d love to hear from you! Whether you have a question, feedback, or need assistance, please reach out to us using the contact information below or fill out the form.
            </p>
          </div>
        </div>
      </section>

      <section className="contact-details-form">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-details">
                <h4 className="contact-title">Contact Information</h4>
                <div className="contact-item">
                  <FaMapMarkerAlt className="contact-icon" />
                  <div className="contact-info">
                    <p><strong>Address:</strong></p>
                    <p>C - 53, First Floor, Sector 2, Noida, Uttar Pradesh - 201301</p>
                  </div>
                </div>
                <div className="contact-item">
                  <FaPhoneAlt className="contact-icon" />
                  <div className="contact-info">
                    <p><strong>Phone:</strong> 0120 4354103</p>
                  </div>
                </div>
                <div className="contact-item">
                  <FaEnvelope className="contact-icon" />
                  <div className="contact-info">
                    <p><strong>Email:</strong> <a href="mailto:info@ndtechland.com">help@ndtechland.com</a></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="contact-form">
                <h4 className="form-title">Contact Us</h4>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea
                      id="message"
                      className="form-control"
                      rows="5"
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contactus;
