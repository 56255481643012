import React from 'react';
import './Termscondition.css';
import Header from './Header';
import Footer from './Footer';

const Termsconditions = () => {
    return (
        <div>
            <Header />
            <div>
                <section className="terms-section">
                    <div className="terms-container container">
                        <h3><strong>Terms and Conditions</strong></h3>
                        <p>These Terms and Conditions ("Agreement") govern your use of N D Techland Private Limited ("we," "us," "our") services, including the N D Connect platform ("Service"). By accessing or using our Service, you agree to be bound by these Terms. If you do not agree, you should immediately stop using the Service.</p>
                        
                        <h5><strong>1. Acceptance of Terms</strong></h5>
                        <p>By registering for, accessing, or using N D Connect, you accept and agree to be bound by these Terms and Conditions, along with any other policies referenced herein. You must be at least 18 years old to use our services.</p>

                        <h5><strong>2. Modifications to Terms</strong></h5>
                        <p>We reserve the right to change, modify, or update these Terms at any time. Any changes will be effective immediately upon posting. Your continued use of the Service after such changes constitutes your acceptance of the updated Terms. We encourage you to periodically review these Terms.</p>

                        <h5><strong>3. Services Provided</strong></h5>
                        <p>N D Connect is an all-in-one business software solution designed to streamline operations such as customer management, invoicing, employee tracking, payment reminders, attendance, and more. We offer both web-based and mobile applications for your convenience.</p>

                        <h5><strong>4. User Accounts</strong></h5>
                        <h6><strong>4.1 Account Registration</strong></h6>
                        <p>You must provide accurate, complete, and current information when registering for an account on N D Connect. You are responsible for safeguarding your password and for any activities or actions under your account. If you discover any unauthorized access or use of your account, you must notify us immediately.</p>
                        <h6><strong>4.2 User Responsibility</strong></h6>
                        <p>You agree to:</p>
                        <ul>
                            <li>Provide accurate and updated information.</li><br />
                            <li>Keep your login credentials confidential.</li><br />
                            <li>Be responsible for all activities conducted through your account.</li><br />
                        </ul>

                        <h5><strong>5. Use of Services</strong></h5>
                        <h6><strong>5.1 Permitted Use</strong></h6>
                        <p>You agree to use N D Connect solely for lawful business operations. You are prohibited from using the Service for any illegal or unauthorized purposes.</p>
                        <h6><strong>5.2 Prohibited Use</strong></h6>
                        <p>You may not:</p>
                        <ul>
                            <li>Engage in any fraudulent or illegal activities.</li><br />
                            <li>Copy, modify, or distribute any part of the Service without authorization.</li><br />
                            <li>Attempt to access unauthorized areas of our Service.</li><br />
                        </ul>

                        <h5><strong>6. Payments and Billing</strong></h5>
                        <h6><strong>6.1 Subscription</strong></h6>
                        <p>Access to certain features of N D Connect may require a subscription. By subscribing, you agree to pay the applicable fees and charges, including all applicable taxes. Failure to make timely payments may result in suspension or termination of your account.</p>
                        <h6><strong>6.2 Payment Methods</strong></h6>
                        <p>We accept payments via credit card, debit card, and other payment methods as listed on our website. You are responsible for maintaining accurate payment information.</p>

                        <h5><strong>7. Renewal and Cancellation</strong></h5>
                        <h6><strong>7.1 Auto-Renewal</strong></h6>
                        <p>Your subscription will automatically renew at the end of each billing cycle unless you cancel before the renewal date. You will be charged the current rate for the following subscription period unless otherwise specified.</p>
                        <h6><strong>7.2 Cancellation</strong></h6>
                        <p>You may cancel your subscription at any time, but you will not be entitled to a refund for any unused portion of your subscription.</p>

                        <h5><strong>8. Intellectual Property</strong></h5>
                        <h6><strong>8.1 Ownership</strong></h6>
                        <p>All content, features, and functionality, including but not limited to text, graphics, logos, icons, and images, are the exclusive property of N D Techland Private Limited and are protected by copyright, trademark, and other applicable laws.</p>
                        <h6><strong>8.2 Limited License</strong></h6>
                        <p>We grant you a limited, non-exclusive, non-transferable license to access and use N D Connect for business purposes. This license does not allow for resale or distribution of any content or material from our Service.</p>

                        <h5><strong>9. Data Privacy and Security</strong></h5>
                        <p>Your use of the Service is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. By using the Service, you consent to the collection and use of information as outlined in the Privacy Policy.</p>

                        <h5><strong>10. Termination of Service</strong></h5>
                        <p>We reserve the right to terminate or suspend your access to N D Connect without prior notice if we determine that you have violated these Terms or engaged in conduct that harms the Service or other users. Upon termination, your access to your account and all related data may be restricted or deleted.</p>

                        <h5><strong>11. Disclaimers and Limitation of Liability</strong></h5>
                        <h6><strong>11.1 Disclaimer of Warranties</strong></h6>
                        <p>The Service is provided on an "as-is" and "as-available" basis. We make no warranties or representations about the accuracy, reliability, or availability of N D Connect. We disclaim all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                        <h6><strong>11.2 Limitation of Liability</strong></h6>
                        <p>In no event shall N D Techland Private Limited, its affiliates, or its licensors be liable for any indirect, incidental, special, or consequential damages arising out of your use or inability to use the Service.</p>

                        <h5><strong>12. Governing Law</strong></h5>
                        <p>These Terms and your use of N D Connect are governed by the laws of India. Any disputes arising under these Terms shall be resolved exclusively in the courts located in [Jurisdiction].</p>

                        <h5><strong>13. Miscellaneous</strong></h5>
                        <h6><strong>13.1 Entire Agreement</strong></h6>
                        <p>These Terms constitute the entire agreement between you and N D Techland Private Limited regarding your use of N D Connect.</p>
                        <h6><strong>13.2 Severability</strong></h6>
                        <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the remaining provisions remain in full force and effect.</p>

                        <h5><strong>Contact Us</strong></h5>
                        <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at:</p>
                        <p>N D Techland Private Limited</p>
                        <p>Email: <a href="mailto:info@ndtechland.com">info@ndtechland.com</a></p>
                        <p>Phone: 0120-4354103</p>
                        <p>Website: <a href="https://www.ndtechland.com">www.ndtechland.com</a></p>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Termsconditions;
