import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './third.css'
const Thirdsection = () => {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await fetch('https://api.abhyaranyan.com/api/Abhyaranyan/Homepage');
                const data = await response.json();
                if (data.succeeded && data.data && data.data.roomsList) {
                    setRooms(data.data.roomsList);
                }
            } catch (error) {
                console.error('Error fetching room data:', error);
            }
        };

        fetchRooms();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            <section className="ticket-section section-b-space white-section animated-section">
                <div class="feat bg-gray">
                    <div class="container">
                        <div class="row">
                            <div class="section-head col-sm-12">
                                <h4><span>Why Choose</span> N D Connect?</h4>
                                <p>In today's fast-paced business environment, managing various aspects of your company efficiently can be challenging. That's where N D Connect, a product of N D Techland Private Limited, comes into play. Our platform is designed to be the ultimate solution for businesses looking to streamline their operations, enhance productivity, and ensure that nothing falls through the cracks.</p>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="item">
                                    <span class="icon feature_box_col_one"><i class="fa fa-cogs"></i></span>
                                    <h6>Comprehensive Management</h6>
                                    <p>N D Connect consolidates all your business needs into one powerful software. From customer details and invoicing to payment reminders and Annual Maintenance Contract (AMC) management, our platform ensures that every aspect of your business is covered.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="item">
                                    <span class="icon feature_box_col_two"><i class="fa fa-users"></i></span>
                                    <h6>Employee Management</h6>
                                    <p>Keep track of your workforce effortlessly with our employee management features. N D Connect offers tools for attendance tracking, leave management, and salary slip generation. All employee-related data is stored securely and can be accessed easily whenever needed.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="item">
                                    <span class="icon feature_box_col_three"><i class="fa fa-clock"></i></span>
                                    <h6>Smart Attendance Tracking</h6>
                                    <p>Our attendance system goes beyond the basics. Employees can mark their attendance through the app or website, and with our Google Maps integration, their location is verified in real-time. This means if someone tries to clock in outside the designated area set by your company, they won't be able to log in. This ensures accurate attendance records and promotes discipline within your organization.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="item">
                                    <span class="icon feature_box_col_four"><i class="fa fa-bell"></i></span>
                                    <h6>Payment and AMC Reminders</h6>
                                    <p>Never miss a payment or AMC renewal again with our automated reminder system. N D Connect allows you to set up reminders for due payments and contracts, helping you maintain smooth operations and avoid any service interruptions.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="item">
                                    <span class="icon feature_box_col_five"><i class="fa fa-plug"></i></span>
                                    <h6>Seamless Integration</h6>
                                    <p>N D Connect is designed to integrate smoothly with your existing systems, ensuring a hassle-free transition and immediate improvement in your business processes.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <div class="item">
                                    <span class="icon feature_box_col_six"><i class="fa fa-thumbs-up"></i></span>
                                    <h6>User-Friendly Interface</h6>
                                    <p>We believe that powerful software should be easy to use. N D Connect features an intuitive interface that requires minimal training, allowing your team to get up and running in no time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Thirdsection;


