import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import Ourstory from './Ourstory';
import Blog from './Blog';
import Missionvission from './Missionvission';
import Pricingplan from './Pricingplan';

import Expertise from './Expertise';
import Profilelogin from './Profilelogin';
import Signup from './Signup';
import Corevalues from './Corevalues';
import Viewrooms from './Viewrooms';
import Featurebenifits from './Featurebenifits';
import Overview from './Overview';
import Otherservices from './Otherservices';
import Requestdemo from './Requestdemo';
import Casestudy from './Casestudy';
import Usermanuals from './Usermanuals';
import Contactus from './Contactus';
import Chartedfirmservices from './Chartedfirmservices';
import Lawfirmservices from './Lawfirmservices';
import Publicrelation from './Publicrelation';
import Digitalmarketing from './Digitalmarketing';
import Termsconditions from './Termsconditions';
import Privacypolicy from './Privacypolicy';
import Cookiepolicy from './Cookiepolicy';
import Datasecurity from './Datasecurity';
import Helpcenter from './Helpcenter';
import Faq from './Faq';
import Customersupport from './Customersupport';
import Userguide from './Userguide';
import Userlogin from './Userlogin';
import Dashboards from './Dashboards/Dashboards'
import Profile from './Dashboards/Profile';
import Bankdetail from './Dashboards/Bankdetail';
import Taskmanager from './Dashboards/Taskmanager';
import Changepassword from './Changepassword';
import Events from './Dashboards/Events';
import Attendance from './Dashboards/Attendance';
import Refunpolicy from './Refunpolicy';
import Datasafety from './Datasafety';
import Currentmonthattandance from './Dashboards/Currentmonthattandance'
import Blogdetails from './Blogdetails';
import Usersguide from './Usersguide';
import Casestudydetails from './Casestudydetails';
function App() {

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/Ourstory" element={<Ourstory />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Missionvission" element={<Missionvission />} />
          <Route path="/Pricingplan" element={<Pricingplan />} />
          <Route path="/Usersguide" element={<Usersguide />} />
          <Route path="/Expertise" element={<Expertise />} />
          <Route path="/Profilelogin" element={<Profilelogin />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Corevalues" element={<Corevalues />} />
          <Route path="/Viewrooms" element={<Viewrooms />} />
          <Route path="/Featurebenifits" element={<Featurebenifits />} />
          <Route path="/Overview" element={<Overview />} />
          <Route path="/Otherservices" element={<Otherservices />} />
          <Route path="/Requestdemo" element={<Requestdemo />} />
          <Route path="/Casestudy" element={<Casestudy />} />
          <Route path="/Usermanuals" element={<Usermanuals />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/Chartedfirmservices" element={<Chartedfirmservices />} />
          <Route path="/Lawfirmservices" element={<Lawfirmservices />} />
          <Route path="/Publicrelation" element={<Publicrelation />} />
          <Route path="/Digitalmarketing" element={<Digitalmarketing />} />
          <Route path="/Termsconditions" element={<Termsconditions />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Cookiepolicy" element={<Cookiepolicy />} />
          <Route path="/Datasecurity" element={<Datasecurity />} />
          <Route path="/Helpcenter" element={<Helpcenter />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/Customersupport" element={<Customersupport />} />
          <Route path="/Userguide" element={<Userguide />} />
          <Route path="/Userlogin" element={<Userlogin />} />
          <Route path="/Dashboards" element={<Dashboards />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Bankdetail" element={<Bankdetail />} />
          <Route path="/Taskmanager" element={<Taskmanager />} />
          <Route path="/Changepassword" element={<Changepassword />} />
          <Route path="/Attendance" element={<Attendance />} />
          <Route path="/Refunpolicy" element={<Refunpolicy />} />
          <Route path="/Datasafety" element={<Datasafety />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Currentmonthattandance" element={<Currentmonthattandance />} />
          <Route path="/Blogdetails/:id" element={<Blogdetails />} />
          <Route path="/casestudydetails/:id" element={<Casestudydetails />} />
        </Routes>

      </Router>

      <div className="tap-top" onClick={handleScrollTop} style={{ cursor: 'pointer' }}>
        <div>
          <i className="fas fa-angle-up"></i>
        </div>
      </div>
    </div>
  );
}

export default App;
