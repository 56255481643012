import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Taskmanager.css';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Tab, Nav } from 'react-bootstrap';

const Taskmanager = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTask, setSelectedTask] = useState(null);
  const [reply, setReply] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [taskModules, setTaskModules] = useState({});
  const [taskModuleList, setTaskModuleList] = useState([]);
  const token = localStorage.getItem('token');
  const [reAssignedTasks, setReAssignedTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [incompleteTasks, setIncompleteTasks] = useState([]);
  const [isSubtaskModalOpen, setIsSubtaskModalOpen] = useState(false);
  const [subtaskDetails, setSubtaskDetails] = useState([]);

  // Fetch tasks
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/EmpTasksassign', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.succeeded) {
          const apiTasks = response.data.data.map(task => ({
            id: task.id,
            title: task.taskTittle,
            description: task.taskDescription,
            startDate: new Date(task.taskstartdate.split('/').reverse().join('-')),
            endDate: new Date(task.taskEnddate.split('/').reverse().join('-')),
            completed: task.taskStatus === 'Completed',
            reply: ''
          }));

          setTasks(apiTasks);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, [token]);

  // Fetch task modules by task ID
  const fetchTaskModules = async (taskId) => {
    try {
      const response = await axios.get(`https://api.ndtechland.com/api/EmployeeApi/EmpTasksassignbyid?id=${taskId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.succeeded) {
        const modules = response.data.data.empdata.map((module) => ({
          value: module.id,
          label: module.tasksubTittle,
        }));

        setTaskModuleList(modules);
      }
    } catch (error) {
      console.error('Error fetching task modules:', error);
    }
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
    fetchTaskModules(task.id);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
    setTaskModuleList([]);
    setReply('');
  };

  const handleModuleChange = (selectedOptions) => {
    setTaskModules((prevModules) => ({
      ...prevModules,
      [selectedTask.id]: selectedOptions
    }));
  };

  const handleSubmitReply = async () => {
    const selectedModuleIds = taskModules[selectedTask.id]?.map(module => module.value) || [];
    const formData = new FormData();
    formData.append('token', token);
    formData.append('subtaskid', JSON.stringify([selectedTask.id]));
    formData.append('taskid', JSON.stringify(selectedModuleIds));
    formData.append('Taskreason', reply);
    formData.append('Taskstatus', selectedTask.completed ? 'Completed' : 'UnCompleted');

    try {
      const response = await axios.post('https://api.ndtechland.com/api/EmployeeApi/AddTasksassign', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.succeeded) {
        const updatedTasks = tasks.map(task =>
          task.id === selectedTask.id ? { ...task, reply, completed: selectedTask.completed } : task
        );
        setTasks(updatedTasks);
        setReply('');
        setIsModalOpen(false);

        Swal.fire({
          title: 'Success',
          text: "Task Detail's submitted successfully",
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } else {
        console.error('Error adding task assignment:', response.data.message);
      }
    } catch (error) {
      console.error('Error submitting task assignment:', error);
    }
  };

  const handleViewTaskHistory = () => {
    setIsHistoryModalOpen(true);
  };

  const closeHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };



  // Filter tasks for the selected date
  const tasksForSelectedDate = tasks.filter(
    task => selectedDate >= task.startDate && selectedDate <= task.endDate
  );

  useEffect(() => {
    const fetchTaskLists = async () => {
      try {
        const response = await axios.get('https://api.ndtechland.com/api/EmployeeApi/EmpTasklist', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.succeeded) {
          const { reassigned, completed, unCompleted } = response.data.data;

          const formattedTasks = {
            reassigned: reassigned.map(task => ({
              id: task.id,
              title: task.taskname,
              duration: task.duration,
              status: task.status,
            })),
            completed: completed.map(task => ({
              id: task.id,
              title: task.taskname,
              duration: task.duration,
              status: task.status,
            })),
            unCompleted: unCompleted.map(task => ({
              id: task.id,
              title: task.taskname,
              duration: task.duration,
              status: task.status,
            })),
          };

          setReAssignedTasks(formattedTasks.reassigned);
          setCompletedTasks(formattedTasks.completed);
          setIncompleteTasks(formattedTasks.unCompleted);
        }
      } catch (error) {
        console.error('Error fetching task lists:', error);
      }
    };

    fetchTaskLists();
  }, [token]);


  const fetchSubtasks = async (taskId) => {
    try {
      const response = await axios.get(`https://api.ndtechland.com/api/EmployeeApi/EmpSubTasklist?SubTaskid=${taskId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.succeeded) {
        setSubtaskDetails(response.data.data);
        setIsSubtaskModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching subtasks:', error);
    }
  };



  return (
    <div className="task-manager">
      <h1 className="task-manager__title">Task Manager</h1>

      <div className="task-manager__layout">
        <aside className="task-manager__sidebar">
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileClassName={({ date, view }) => {
              if (view === 'month') {
                const sameDay = (d1, d2) =>
                  d1.getFullYear() === d2.getFullYear() &&
                  d1.getMonth() === d2.getMonth() &&
                  d1.getDate() === d2.getDate();

                const isToday = sameDay(date, new Date());

                const hasTask = tasks.some(
                  task =>
                    sameDay(date, task.startDate) ||
                    (date > task.startDate && date < task.endDate) ||
                    sameDay(date, task.endDate)
                );

                if (hasTask) {
                  return 'task-assigned';
                } else if (isToday) {
                  return 'current-date';
                }
              }
              return '';
            }}
          />
        </aside>

        <main className="task-manager__content">
          <div className="task-manager__header-container d-flex">
            <h2>Tasks for {selectedDate.toDateString()}</h2>
            <button className="task-manager__history-button" onClick={handleViewTaskHistory}>
              View Task History
            </button>
          </div>
          <div className="task-manager__cards">
            {tasksForSelectedDate.length > 0 ? (
              tasksForSelectedDate.map((task) => (
                <div
                  key={task.id}
                  className={`task-manager__card ${task.completed ? 'completed' : 'pending'}`}
                  onClick={() => handleTaskClick(task)}
                >
                  <div className="task-manager__text">
                    <div className="task-manager__header">
                      <h3>{task.title}</h3>
                      <p className={`task-manager__status ${task.completed ? 'status-completed' : 'status-pending'}`}>
                        {task.completed ? 'Completed' : 'Pending'}
                      </p>
                    </div>
                    <p className="task-manager__description">{task.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No tasks for this date.</p>
            )}
          </div>
        </main>
      </div>

      {/* Task History Modal */}
      {isHistoryModalOpen && (
        <div className="modal show d-block" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Task History</h5>
                <button type="button" className="close" onClick={closeHistoryModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Tab.Container defaultActiveKey="completed">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="completed">Completed</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="reassigned">Re-Assigned</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="incomplete">Incomplete</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="completed">
                      {completedTasks.length > 0 ? completedTasks.map(task => (
                        <div key={task.id}>
                          <h6 onClick={() => fetchSubtasks(task.id)} style={{ cursor: 'pointer' }}>
                            {task.title}
                          </h6>

                          <p>{task.duration}</p>
                        </div>
                      )) : <p>No completed tasks.</p>}
                    </Tab.Pane>
                    <Tab.Pane eventKey="reassigned">
                      {reAssignedTasks.length > 0 ? reAssignedTasks.map(task => (
                        <div key={task.id}>
                          <h6 onClick={() => fetchSubtasks(task.id)} style={{ cursor: 'pointer' }}>
                            {task.title}
                          </h6>

                          <p>{task.duration}</p>
                        </div>
                      )) : <p>No re-assigned tasks.</p>}
                    </Tab.Pane>
                    <Tab.Pane eventKey="incomplete">
                      {incompleteTasks.length > 0 ? incompleteTasks.map(task => (
                        <div key={task.id}>
                          <h6 onClick={() => fetchSubtasks(task.id)} style={{ cursor: 'pointer' }}>
                            {task.title}
                          </h6>

                          <p>{task.duration}</p>
                        </div>
                      )) : <p>No incomplete tasks.</p>}
                    </Tab.Pane>

                  </Tab.Content>
                </Tab.Container>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeHistoryModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      {isSubtaskModalOpen && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Subtask Details</h5>
                <button type="button" className="close" onClick={() => setIsSubtaskModalOpen(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {subtaskDetails.length > 0 ? subtaskDetails.map(subtask => (
                  <div key={subtask.id}>
                    <h6>{subtask.taskTittle}</h6>
                    <p><strong>Subtask Title:</strong> {subtask.tasksubTittle}</p>
                    <p><strong>Status:</strong> {subtask.taskStatus}</p>
                    <p><strong>Description:</strong> {subtask.taskDescription}</p>
                    <p><strong>Duration:</strong> {subtask.duration}</p>
                  </div>
                )) : <p>No subtasks available.</p>}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setIsSubtaskModalOpen(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      {isModalOpen && selectedTask && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Task Details</h5>
                <button type="button" className="close" onClick={handleModalClose} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h5>{selectedTask.title}</h5>
                <p>{selectedTask.description}</p>
                <p><strong>Start Date:</strong> {selectedTask.startDate.toDateString()}</p>
                <p><strong>End Date:</strong> {selectedTask.endDate.toDateString()}</p>

                <Select
                  isMulti
                  options={taskModuleList}
                  onChange={handleModuleChange}
                  placeholder="Select Modules"
                />

                <textarea
                  className="form-control mt-3"
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  placeholder="Add your reply..."
                  rows="4"
                />
              </div>
              <div className="modal-footer d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="completedCheckbox"
                    checked={selectedTask.completed}
                    onChange={(e) => setSelectedTask({ ...selectedTask, completed: e.target.checked })}
                  />
                  <label className="form-check-label" htmlFor="completedCheckbox">
                    Completed
                  </label>
                </div>
                <div>
                  <button type="button" className="btn btn-secondary me-2" onClick={handleModalClose}>
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleSubmitReply}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Taskmanager;
