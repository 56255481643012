import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Banner.css';

// Static image imports
import ban1 from '../src/images/ban1.jpg';
import ban2 from '../src/images/ban2.jpg';
import ban3 from '../src/images/ban3.jpg';
import ban4 from '../src/images/ban4.jpg';
import ban5 from '../src/images/ban5.jpg';
import ban6 from '../src/images/ban6.jpg';
import ban7 from '../src/images/ban7.jpg';
import ban8 from '../src/images/ban8.jpg';
import ban9 from '../src/images/ban9.jpg';

// Custom Arrow Components
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} custom-arrow slick-prev`}
      onClick={onClick}
    >
      {/* Add custom arrow icon here */}
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} custom-arrow slick-next`}
      onClick={onClick}
    >
      {/* Add custom arrow icon here */}
    </button>
  );
};

const Banner = () => {
  // Static list of images
  const banners = [
    { imageUrl: ban9 },
    { imageUrl: ban1 },
    { imageUrl: ban2 },
    { imageUrl: ban3 },
    { imageUrl: ban4 },
    { imageUrl: ban5 },
    { imageUrl: ban6 },
    { imageUrl: ban7 },
    { imageUrl: ban8 },
  
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div>
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={index} className="home_section hotel_layout p-0">
            <div>
              <div className="home">
                <img
                  src={banner.imageUrl}
                  alt={`Banner ${index}`}
                  className="img-fluid lazyload bg-img"
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
