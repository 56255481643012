import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Expertise = () => {
  const [expertiseData, setExpertiseData] = useState([]);

  useEffect(() => {
    const fetchExpertiseData = async () => {
      try {
        const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetOurExpertise');
        const result = await response.json();

        if (result.status === 200) {
          setExpertiseData(result.data);
        } else {
          console.error('Error fetching expertise data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching expertise data:', error);
      }
    };

    fetchExpertiseData();
  }, []);

  return (
    <div>
      <Header />
      <br />
      <section className="about-section about_page animated-section">
        <div className="section-head col-sm-12">
          <h4><span>Our Expertise</span></h4>
        </div>
        <div className="container">
          {expertiseData.map((expertise, index) => (
            <div className="card-section mb-5" key={expertise.id}>
              <div className="row">
                {index % 2 === 0 ? (
                  <>
                    <div className="col-lg-6 d-flex align-items-center">
                      <div className="card-content">
                        <h3>{expertise.expertiseName}</h3>
                        <p>{expertise.description}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src={`https://api.ndtechland.com/image/${expertise.experiseImage}`}
                        alt={expertise.expertiseName}
                        className="img-fluid rounded"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 order-lg-2">
                      <img
                        src={`https://api.ndtechland.com/image/${expertise.experiseImage}`}
                        alt={expertise.expertiseName}
                        className="img-fluid rounded"
                      />
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                      <div className="card-content">
                        <h3>{expertise.expertiseName}</h3>
                        <p>{expertise.description}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Expertise;
