import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './Casestudydetails.css';

const Casestudydetails = () => {
    const { id } = useParams();  // Get the id from URL parameters
    const [caseStudy, setCaseStudy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCaseStudy = async () => {
            try {
                const response = await fetch(`https://api.ndtechland.com/api/HomeApi/GetCaseStudyById?Id=${id}`);
                const data = await response.json();

                if (data.status === 200) {
                    setCaseStudy(data.data);
                } else {
                    throw new Error(data.message);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCaseStudy();
    }, [id]);  // Fetch case study when id changes

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!caseStudy) return <div>No case study found.</div>;

    return (
        <>
            <Header />
            <section className="casestudy-section">
                <div className="container">
                    <div className="section-head col-sm-12">
                        <h4 style={{ marginTop: "6rem" }}><span>Recent Success Stories</span></h4>
                    </div>
                    <div className="text-center">
                        <p className="section-description">
                            Discover how N D Connect has transformed businesses across industries. Explore our case studies to see the real-world impact of our platform.
                        </p>
                    </div>
                    <h2 className="case-study-title">{caseStudy.title}</h2>
                    <div className="case-study-detail">
                        <img 
                            src={`https://api.ndtechland.com/image/${caseStudy.image}`} 
                            alt={caseStudy.title} 
                            className="case-study-image" 
                        />
                        <div 
                            className="case-study-description" 
                            dangerouslySetInnerHTML={{ __html: caseStudy.description }} 
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Casestudydetails;
