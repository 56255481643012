import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Chartedfirmservices = () => {
    return (
        <div>
            <Header />

            <section className="services-section">
                <div className="services-container container">

                    <div className="service-section">


                        <p>
                            At N D Techland, we understand that businesses require more than just software solutions to succeed in today’s complex environment. That’s why we offer a range of professional services designed to complement our software products and support your business in various areas. Our expertise extends beyond technology, providing you with a holistic approach to managing and growing your business.
                        </p>

                        <h3>Chartered Firm Services</h3>
                        <p>
                            Our Chartered Firm services are tailored to meet the financial and compliance needs of businesses of all sizes. We offer a wide range of accounting, auditing, taxation, and advisory services to help you manage your finances effectively and ensure compliance with regulatory requirements.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Accounting & Bookkeeping</b></h5>
                                <p>
                                    We provide comprehensive accounting and bookkeeping services, ensuring that your financial records are accurate, up-to-date, and compliant with relevant standards. Our services include maintaining general ledgers, preparing financial statements, and reconciling accounts.
                                </p>
                            </li>
                            <li>
                                <h5><b>Taxation Services</b></h5>
                                <p>
                                    Navigating the complex tax landscape can be challenging. Our expert tax consultants help you manage your tax obligations efficiently, offering services such as tax planning, preparation, filing, and representation before tax authorities. We aim to minimize your tax liabilities while ensuring compliance with all applicable laws.
                                </p>
                            </li>
                            <li>
                                <h5><b>Audit & Assurance</b></h5>
                                <p>
                                    Our audit and assurance services are designed to give you confidence in your financial statements. We conduct thorough audits, reviews, and assessments of your financial operations, helping you identify risks, improve controls, and enhance transparency.
                                </p>
                            </li>
                            <li>
                                <h5><b>Financial Advisory</b></h5>
                                <p>
                                    Whether you’re planning to expand your business, manage cash flow, or secure funding, our financial advisory services provide you with the insights and strategies needed to make informed decisions. We offer tailored advice on mergers and acquisitions, business valuations, and financial restructuring.
                                </p>
                            </li>
                        </ul>
                    </div>




                    <div className="tailored-solutions">
                        <h3>Tailored Solutions for Your Business</h3>
                        <p>
                            At N D Techland, we believe that every business is unique, and so are its needs. That’s why we offer tailored solutions across our services, ensuring that you receive the support and expertise required to achieve your specific objectives. Whether you’re looking to optimize your finances, navigate legal challenges, enhance your digital presence, or build your brand’s reputation, we have the skills and experience to help you succeed.
                        </p>
                    </div>

                    <div className="get-in-touch">
                        <h3>Get in Touch</h3>
                        <p>
                            Ready to take your business to the next level? Contact us today to discuss your requirements and learn more about how our services can benefit your organization. Our team is here to answer your questions and provide you with the guidance you need to make informed decisions.
                        </p>
                    </div>


                </div>


            </section >
            <Footer />
        </div >
    );
}

export default Chartedfirmservices;
