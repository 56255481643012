import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './Userlogin.css';
import { Link } from 'react-router-dom';

const Changepassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match.');
      return;
    }

    const token = localStorage.getItem('token');

    try {
      console.log('Sending data:', { currentPassword, newPassword });

      const response = await axios.post('https://api.ndtechland.com/api/EmployeeApi/EmployeeChangePassword', {
        currentPassword,
        newPassword,
        confirmPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Response:', response.data);

      if (response.data) {
        // Show success alert
        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
        
          navigate('/Dashboards');
        });
      } else {
   
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error.response || error);
      setErrorMessage('Failed to change password. Please try again later.');
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-sm-10 col-12 form-container">
          <div className="account-sign-in shadow-lg p-4 rounded">
            <div className="title text-center">
              <h2>Change Password</h2>
            </div>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="currentPassword" style={{ textAlign: "left" }}>Current Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="currentPassword"
                  placeholder="Enter your current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword" style={{ textAlign: "left" }}>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  placeholder="Enter your new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword" style={{ textAlign: "left" }}>Confirm New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div className="button-bottom mt-4">
                <button type="submit" className="w-100 btn btn-primary btn-lg">Change Password</button>
                <div className="divider text-center my-3">
                  <h6><Link to="/Userlogin">Back to Login</Link></h6>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
