import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Lawfirmservices = () => {
    return (
        <>

            <Header />

            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3>Law Firm Services</h3>
                        <p>
                            Legal matters are an integral part of any business, and having access to expert legal advice is crucial for long-term success. Our Law Firm services provide you with comprehensive legal support, covering a wide range of business-related legal issues.
                        </p>
                        <ul>
                            <li>
                                <h5><b>Corporate Law</b></h5>
                                <p>
                                    We offer legal services related to the formation, governance, and operation of businesses. Our corporate law services include company registration, drafting and reviewing contracts, shareholder agreements, mergers and acquisitions, and corporate restructuring.
                                </p>
                            </li>
                            <li>
                                <h5><b>Intellectual Property (IP) Law</b></h5>
                                <p>
                                    Protecting your intellectual property is essential in today’s competitive market. Our IP law services help you secure patents, trademarks, copyrights, and trade secrets. We also provide assistance with IP litigation and enforcement.
                                </p>
                            </li>
                            <li>
                                <h5><b>Employment Law</b></h5>
                                <p>
                                    Navigating employment laws can be complex, but our legal experts are here to help. We offer services related to drafting employment contracts, handling disputes, ensuring compliance with labor laws, and managing employee-related litigation.
                                </p>
                            </li>
                            <li>
                                <h5><b>Dispute Resolution</b></h5>
                                <p>
                                    Disputes can arise in any business, and resolving them effectively is critical to maintaining relationships and protecting your interests. Our dispute resolution services include mediation, arbitration, and litigation, helping you achieve favorable outcomes with minimal disruption to your business.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Lawfirmservices
