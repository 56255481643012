import React from 'react';
import Header from './Header';
import Footer from './Footer';

const RefundPolicy = () => {
    const sectionStyle = {
        listStyleType: 'none', 
        padding: 0,
    };

    const listItemStyle = {
        marginBottom: '10px', 
    };

    return (
        <div>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="service-section">
                        <h3>Refund Policy</h3>
                        <p>Thank you for choosing N D Connect, a product of N D Techland Private Limited. We are committed to providing a seamless and efficient service. Please carefully read our Refund Policy outlined below.</p>
                        
                        <h5>No Refunds Policy</h5>
                        <p>Once your account has been activated after successful payment and the signing of the Memorandum of Understanding (MOU), you cannot cancel your subscription, and no refunds will be issued. By proceeding with the payment and activation of your N D Connect account, you acknowledge and agree to this policy.</p>
                        
                        <h5>Subscription Activation</h5>
                        <p>Once payment is processed and your account is activated, your subscription begins, and you will have full access to all features and services of N D Connect as agreed in the MOU. The service provided is non-refundable and non-cancellable once your account is live.</p>
                        
                        <h5>Exceptions</h5>
                        <p>In exceptional circumstances, if there is a technical issue on our end that prevents you from accessing or using the service, we may evaluate requests for refunds or alternative resolutions on a case-by-case basis.</p>
                        
                        <p>For any inquiries regarding this policy, please contact us at <a href="mailto:info@ndtechland.com">info@ndtechland.com</a> or call 0120-4354103. We are here to assist you!</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default RefundPolicy;
