import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Missionvission = () => {
    const [missionVisionData, setMissionVisionData] = useState([]);

    useEffect(() => {
        const fetchMissionVision = async () => {
            try {
                const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetMissionVisions');
                const data = await response.json();

                if (data.status === 200) {
                    setMissionVisionData(data.data);
                }
            } catch (error) {
                console.error('Error fetching mission and vision:', error);
            }
        };

        fetchMissionVision();
    }, []);

    return (
        <div>
            <Header />

            <section className="about-section about_page animated-section">
                <div className="section-head col-sm-12">
                    <h4><span>Mission & Vision</span></h4>
                </div>
                <div className="container">
                    {missionVisionData.map((item, index) => (
                        <div className="row align-items-center mt-5" key={item.id}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="col-lg-6">
                                        <img
                                            src={`https://api.ndtechland.com/image/${item.image}`}
                                            alt={item.missionVisionName}
                                            className="img-fluid rounded mb-4"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="section-head">
                                            <h5><b>{item.missionVisionName}</b></h5>
                                            <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item.description }} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6 order-lg-2">
                                        <img
                                            src={`https://api.ndtechland.com/image/${item.image}`}
                                            alt={item.missionVisionName}
                                            className="img-fluid rounded mb-4"
                                        />
                                    </div>
                                    <div className="col-lg-6 order-lg-1">
                                        <div className="section-head">
                                            <h5><b>{item.missionVisionName}</b></h5>
                                            <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item.description }} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Missionvission;
