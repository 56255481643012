import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Header from './Header';
import Footer from './Footer';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';

const Viewrooms = () => {
    const [roomDetails, setRoomDetails] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const checkInDate = searchParams.get('checkInDate');
    const checkOutDate = searchParams.get('checkOutDate');
    const totalrooms = searchParams.get('totalrooms');
    const totalguest = searchParams.get('totalguest');
    const id = searchParams.get('id');


    useEffect(() => {
        if (checkInDate && checkOutDate) {
            const formattedCheckInDate = moment(checkInDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            const formattedCheckOutDate = moment(checkOutDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

            const fetchData = async () => {
                try {
                    const response = await axios.get(`https://api.abhyaranyan.com/api/Abhyaranyan/Roomdetails`, {
                        params: {
                            checkInDate: formattedCheckInDate,
                            checkOutDate: formattedCheckOutDate,
                            totalrooms,
                            totalguest,
                            id: id
                        },
                    });
                    setRoomDetails(response.data.rooms || {});
                } catch (error) {
                    setError('Failed to fetch room details');
                }
            };

            fetchData();
        }
    }, [checkInDate, checkOutDate, totalrooms, totalguest]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        script.onload = () => console.log('Razorpay script loaded');
        script.onerror = () => console.error('Failed to load Razorpay script');
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePayment = async () => {
        const firstName = document.getElementById('firstName').value;
        const lastName = document.getElementById('lastName').value;
        const email = document.getElementById('emailHelp').value;
        const contact = document.getElementById('mobile-no').value;
        const gender = document.getElementById('Gender').value;
        const address = document.getElementById('Address').value;

        try {
            const response = await axios.post('https://api.abhyaranyan.com/api/Abhyaranyan/BookNow', {
                roomid: id, // Ensure this is set correctly
                firstName,
                lastName,
                gender,
                phone: contact,
                emailAddress: email,
                address,
                amount: roomDetails.amount,  // Ensure this is correctly fetched
                checkInDate: roomDetails.checkInDate,
                checkOutDate: roomDetails.checkOutDate,
                totalPerson: totalguest,
                numberofRooms: totalrooms,
                paymentMode_Id: 0, // Set as per your requirement
                razorpayKey: "rzp_test_bbGUZd7c9hepbD,DexoptlyY6jCK0sh57DMahtz",
                paymentId: "jhjhj", // This should be generated dynamically
                isPaymentStatus: true,
                order_Id: "gff" // This should be generated dynamically
            });

            if (response.data.succeeded) {
                alert('Booking Confirmed successfully!');
                const { paymentId, razorpayKey } = response.data.data;
                const options = {
                    key: razorpayKey,
                    amount: response.data.data.amount * 100,
                    currency: "INR",
                    name: "Your Company Name",
                    description: "Room Booking",
                    handler: function (paymentResponse) {
                        alert("Payment successful! Payment ID: " + paymentResponse.razorpay_payment_id);
                    },
                    prefill: {
                        name: `${firstName} ${lastName}`,
                        email: email,
                        contact: contact,
                    },
                    notes: {
                        address: address,
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            } else {
                alert('Booking failed. Please try again.');
            }
        } catch (error) {
            console.error('Booking failed:', error);
            alert('Booking failed. Please try again.');
        }
    };





    if (error) return <div>{error}</div>;

    return (
        <div>
            <Header />
            <div className="home_section hotel_layout slide-1 p-0">
                <img src="https://content.jdmagicbox.com/comp/amritsar/n6/0183px183.x183.220531142026.f3n6/catalogue/hotel-elite-amritsar-amritsar-hotels-5uelvu3xf5.jpg" style={{ width: "100%", height: "40rem" }} alt="Hotel" />
            </div>
            <section className="section-b-space bg-inner animated-section">
                <div className="animation-section" style={{ marginTop: "5rem" }}>
                    {/* Add your animated section here */}
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="guest-detail">
                                <h2>Guest Information</h2>
                                <form>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col" style={{ textAlign: "left" }}>
                                                <label>First Name</label>
                                                <input type="text" id="firstName" className="form-control" placeholder="First name" style={{ textAlign: "left" }} />
                                            </div>
                                            <div className="col" style={{ textAlign: "left" }}>
                                                <label>Last Name</label>
                                                <input type="text" id="lastName" className="form-control" placeholder="Last name" style={{ textAlign: "left" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col" style={{ textAlign: "left" }}>
                                                <label>Email address</label>
                                                <input type="email" className="form-control" id="emailHelp" placeholder="Enter email" style={{ textAlign: "left" }} />
                                                <small className="form-text text-muted" style={{ textAlign: "left" }}>Booking confirmation will be sent to this email ID.</small>
                                            </div>
                                            <div className="col" style={{ textAlign: "left" }}>
                                                <label>Contact Info</label>
                                                <input id="mobile-no" type="tel" className="form-control" placeholder="Contact Info" style={{ textAlign: "left" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col" style={{ textAlign: "left" }}>
                                                <label htmlFor="Gender">Gender</label>
                                                <select id="Gender" className="form-select" name="Gender" style={{ textAlign: "left" }} required>
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="col" style={{ textAlign: "left" }}>
                                                <label htmlFor="Address" style={{ textAlign: "left" }}>Address</label>
                                                <input type="text" name="Address" id="Address" className="form-control" placeholder="Address" style={{ textAlign: "left" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-btn">
                                        <Button className="btn btn-solid" onClick={handlePayment} type="button">Pay Now</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 booking-order">
                            <div className="summery-box">
                                <h2>Booking Summary</h2>
                                {roomDetails && roomDetails.roomType ? (
                                    <>
                                        <div class="hotel-section">
                                            <div class="hotel-img">
                                                <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${roomDetails.roomImage}`} className="img-fluid blur-up lazyload" alt="Room" />
                                            </div>
                                            <div class="hotel-detail">
                                                <h6>{roomDetails.roomType}</h6>
                                                <p style={{ textAlign: "left" }}>{roomDetails.description}</p>
                                            </div>
                                        </div>
                                        <div class="summery-section">
                                            <div class="box">
                                                <div class="left" style={{ textAlign: "left" }}>
                                                    <div class="up">
                                                        <h6>check in</h6>
                                                        <h5>{roomDetails.checkInDate.split('T')[0]}</h5>
                                                    </div>
                                                    <div class="down">
                                                        <h6>check in time</h6>
                                                        <h5>2.00 pm</h5>
                                                    </div>
                                                </div>
                                                <div class="right">
                                                    <div class="up">
                                                        <h6>check out</h6>
                                                        <h5>{roomDetails.checkOutDate.split('T')[0]}</h5>
                                                    </div>
                                                    <div class="down">
                                                        <h6>check out time</h6>
                                                        <h5>12.00 pm</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="summery-section">
                                            <h5 class="mb-0"><span id="TotalGuests">{roomDetails.totalguest}</span> Guest , <span id="TotalRooms">{roomDetails.totalrooms}</span> Deluxe Room, <span id="numberOfNights">{roomDetails.totalnights}Night(s)</span></h5>
                                            <a href="/Booking" class="edit-cls">edit</a>
                                        </div>
                                        <div class="summery-section">
                                            <div class="payment-details">
                                                <h5>payment details</h5>
                                                <table>
                                                    <tbody>
                                                        <tr style={{ textAlign: "left" }}>
                                                            <td>base price</td>
                                                            <td><del>{roomDetails.oldrent}</del></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="summery-section">
                                            <div class="payment-details">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: "left" }}>payable amount</td>
                                                            <td >{roomDetails.amount}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p>Loading room details...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Viewrooms;
