import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Ourstory.css'; // Ensure this file has the updated styles

const Ourstory = () => {
    const [ourStoryData, setOurStoryData] = useState([]);

    useEffect(() => {
        const fetchOurStory = async () => {
            try {
                const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetOurStory');
                const data = await response.json();

                if (data.status === 200) {
                    setOurStoryData(data.data);
                } else {
                    console.error('Error fetching data:', data.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchOurStory();
    }, []);

    return (
        <div>
            <Header />
            <br />
            <section className="ourstory-section about_page animated-section">
                <div className="container">
                    {ourStoryData.map((item) => (
                        <div key={item.id} className="info-card mt-5">
                            {item.id % 2 === 0 ? (
                                <>
                                    <img
                                        src={`https://api.ndtechland.com/image/${item.image}`} // Update the image URL if needed
                                        alt={item.title}
                                        className="info-image"
                                    />
                                    <div className="info-content">
                                        <h3 className="section-heading">{item.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="info-content">
                                        <h3 className="section-heading">{item.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                    </div>
                                    <img
                                        src={`https://api.ndtechland.com/image/${item.image}`} // Update the image URL if needed
                                        alt={item.title}
                                        className="info-image"
                                    />
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Ourstory;
