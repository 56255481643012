import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://api.ndtechland.com/api/HomeApi/Blogs');
        const data = await response.json();

        if (data.status === 200) {
          setBlogs(data.data);
        } else {
          console.error('Error fetching blogs:', data.message);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      <Header />

      <section className="blog-section py-10">
        <div className="container">
          <div className="text-center py-12">
            <div className="section-head">
              <h4><span>Our Blog</span></h4>
            </div>
            <h3 className="section-subtitle">Latest Insights</h3>
            <p className="section-description">
              Explore our blog for the latest insights, tips, and industry trends to help you thrive in your business.
            </p>
          </div>

          <div className="row">
            {blogs.map(blog => (
              <div className="col-lg-4 col-md-6 mb-4" key={blog.id}>
                <div className="card blog-card shadow-lg">
                  <img
                    src={`https://api.ndtechland.com/image/${blog.blogImage}`}
                    className="card-img-top"
                    alt={blog.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{blog.title}</h5>
                    <div className="blog-meta">
                      <span className="badge bg-info text-white">{blog.category}</span>
                    </div>
                    <p className="card-text" dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                    <Link to={`/Blogdetails/${blog.id}`}>
                      <a className="btn btn-outline-primary">Read More</a>
                    </Link>


                  </div>  {/*href={`/blogdetails/${blog.id}`} */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Blog;
