import React, { useState, useEffect, memo } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css"; // Import lightbox styles
import './Gallerysection.css';
import Lightbox from 'react-image-lightbox';

const MemoizedLightbox = memo(Lightbox); // Memoized Lightbox component

const Gallerysection = () => {
    let summaryCollection = document.getElementsByTagName('summary');
    let signsCollection = document.getElementsByClassName('faq-open-icon');

    for (let i = 0; i < summaryCollection.length; i++) {
        summaryCollection[i].onclick = function () {
            if (signsCollection[i].innerHTML === '+') signsCollection[i].innerHTML = '—';
            else signsCollection[i].innerHTML = '+';
        }
    }

    return (
        <>
            <section className="ratio3_2 pt-0">
                <div className='row'>

                    <div className='col-xl-6'>
                        <main>
                            <div class="container">

                                <div class="section-head col-sm-12">
                                    <h4><span>Frequently asked questions</span></h4>
                                </div>

                                <details class="faq-card">
                                    <summary>What is N D Connect? <span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">N D Connect is an all-in-one business management platform designed to streamline your operations. It covers everything from customer management and invoicing to employee attendance tracking and payment reminders, all within one intuitive software.
                                    </span>
                                </details>
                                <details class="faq-card">
                                    <summary>Who can benefit from using N D Connect?<span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">N D Connect is ideal for businesses of all sizes across various industries. Whether you're a small startup or a large enterprise, our platform adapts to your specific needs, helping you manage your business more effectively.</span>
                                </details>
                                <details class="faq-card">
                                    <summary>How does the location-based attendance system work?  <span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">Our attendance system integrates with Google Maps to verify employees' locations when they log in. Companies can set a specific location radius, and if an employee tries to clock in outside of this area, they won't be able to do so. This ensures accurate attendance records and helps prevent fraudulent logins.
                                    </span>
                                </details>
                                <details class="faq-card">
                                    <summary>Can I customize N D Connect to fit my business needs?  <span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">Yes, N D Connect is highly customizable. We understand that every business is unique, so our platform allows you to tailor its features to match your specific requirements.
                                    </span>
                                </details>
                                <details class="faq-card">
                                    <summary>How secure is my data on N D Connect?  <span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">Security is our top priority. N D Connect uses advanced encryption and security protocols to protect your data. We also offer regular backups and secure access controls to ensure that your information is safe.</span>
                                </details>
                                <details class="faq-card">
                                    <summary>What kind of support do you offer?   <span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">We provide comprehensive support to all our customers. Whether you need help with setup, customization, or troubleshooting, our dedicated support team is here to assist you every step of the way.
                                    </span>
                                </details>
                                <details class="faq-card">
                                    <summary>Can I try N D Connect before committing?   <span class="faq-open-icon">+</span></summary>
                                    <span class="faq-card-spoiler">Absolutely! We offer a free demo of N D Connect so you can explore its features and see how it can benefit your business. Contact us to schedule your demo today.
                                    </span>
                                </details>


                            </div>
                        </main>
                    </div>
                    <div className='col-xl-6'>
                        <div className="container">
                            <div className="section-head col-sm-12">
                                <h4><span>Additional Details</span></h4>
                            </div>

                            <details className="faq-card">
                                <summary>What is Real-Time Analytics and Reporting? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">With N D Connect, you can make data-driven decisions with confidence. Our platform provides real-time analytics and customizable reports, giving you valuable insights into your business operations. Track performance, monitor trends, and identify opportunities for growth with ease.</span>
                            </details>
                            <details className="faq-card">
                                <summary>What are Scalable Solutions for Growing Businesses? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">As your business grows, N D Connect grows with you. Our platform is designed to scale effortlessly, ensuring that you can continue to manage your expanding operations without any disruptions.</span>
                            </details>
                            <details className="faq-card">
                                <summary>How does N D Connect integrate with popular tools? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">N D Connect seamlessly integrates with popular tools and platforms like Google Workspace, QuickBooks, and more. This allows you to maintain your existing workflows while enhancing your capabilities with our software.</span>
                            </details>
                            <details className="faq-card">
                                <summary>What automation features does N D Connect offer? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">Save time and reduce manual errors with N D Connect's automation features. From sending payment reminders to generating salary slips, our platform automates repetitive tasks, freeing up your team to focus on more strategic activities.</span>
                            </details>
                            <details className="faq-card">
                                <summary>How does Mobile Access work with N D Connect? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">Stay connected to your business from anywhere. N D Connect is accessible via mobile devices, allowing you to manage your operations on the go. Whether you're traveling or working remotely, you have full control at your fingertips.</span>
                            </details>
                            <details className="faq-card">
                                <summary>What is the process for Tailored Onboarding and Training? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">We understand that adopting new software can be daunting. That's why we offer tailored onboarding and training sessions to ensure a smooth transition. Our experts will guide you through the setup process and provide training to your team, so you can get the most out of N D Connect.</span>
                            </details>
                            <details className="faq-card">
                                <summary>What are the Affordable Pricing Plans for N D Connect? <span className="faq-open-icon">+</span></summary>
                                <span className="faq-card-spoiler">N D Connect offers flexible pricing plans to suit businesses of all sizes. We believe in providing value, so our plans are designed to be affordable without compromising on quality or features. Contact us to discuss a plan that works best for your business.</span>
                            </details>
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
}

export default Gallerysection;
