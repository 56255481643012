import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Corevalue.css'; // Import your CSS file for additional styling

function Corevalues() {
    const [coreValues, setCoreValues] = useState([]);

    useEffect(() => {
        const fetchCoreValues = async () => {
            try {
                const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetOurCoreValues');
                const data = await response.json();
                
                if (data.status === 200) {
                    setCoreValues(data.data);
                }
            } catch (error) {
                console.error('Error fetching core values:', error);
            }
        };

        fetchCoreValues();
    }, []);

    return (
        <div>
            <Header />

            <section className="corevalues-section about_page animated-section">
                <div className="container">
                    <div className="section-head text-center">
                        <h4><span>Our Core Values</span></h4>
                        <p>
                            At the heart of everything we do are our core values, which guide our decisions, actions, and interactions with our clients, partners, and team members:
                        </p>
                    </div>

                    <div className="row mt-5">
                        {coreValues.map(value => (
                            <div className="col-lg-4 mb-4" key={value.id}>
                                <div className="corevalue-card">
                                    <img
                                        src={`https://api.ndtechland.com/image/${value.image}`} // Assuming this is the correct path to the images
                                        alt={value.title}
                                        className="img-fluid rounded"
                                    />
                                    <div className="corevalue-content">
                                        <h5>{value.title}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: value.content }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Corevalues;
