import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';

const Otherservices = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch('https://api.ndtechland.com/api/HomeApi/GetOtherServices');
                if (!response.ok) {
                    throw new Error('Failed to fetch services');
                }
                const { data } = await response.json();
                setServices(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    if (loading) {
        return <div>Loading services... Please wait.</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Header />
            <section className="services-section">
                <div className="services-container container">
                    <div className="section-head col-sm-12">
                        <h4><span>Other Services</span></h4>
                    </div>

                    {services.map((serviceCategory) => (
                        <div className="service-section" key={serviceCategory.id}>
                            <details className="faq-card">
                                <summary style={{ fontSize: "23px" }}>
                                    {serviceCategory.serviceName} <span className="faq-open-icon">+</span>
                                </summary>
                                <ul
                                    dangerouslySetInnerHTML={{ __html: serviceCategory.description }}
                                />
                            </details>
                        </div>
                    ))}

                    <div className="tailored-solutions">
                        <h3>Tailored Solutions for Your Business</h3>
                        <p>At N D Techland, we offer tailored solutions across our services, ensuring that you receive the support and expertise required to achieve your specific objectives.</p>
                    </div>

                    <div className="get-in-touch">
                        <h3>Get in Touch</h3>
                        <p>Ready to take your business to the next level? Contact us today to discuss your requirements and learn more about how our services can benefit your organization.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Otherservices;
